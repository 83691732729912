import React from "react";

// version from response - first param, local version second param
const semverGreaterThan = (versionA, versionB) => {
  const versionsA = (versionA || "").split(/\./g);

  const versionsB = (versionB || "").split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return true;
  }
  return false;
};

class CacheBuster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      version: "0.1.5",
      loading: true,
      isLatestVersion: false,
      refreshCacheAndReload: () => {
        console.log("Clearing cache and hard reloading...");
        if (caches) {
          // Service worker cache should be cleared with caches.delete()
          caches.keys().then(function (names) {
            for (let name of names) caches.delete(name);
          });
        }

        // delete browser cache and hard reload
        window.location.reload(true);
      },
    };
  }

  componentDidMount() {
    const headers = new Headers();
    headers.set("Cache-Control", "no-cache");
    headers.set("Expires", 0);
    headers.set("Pragma", "no-cache");
    fetch("/meta.json", { headers })
      .then((response) => response.json())
      .then((meta) => {
        const latestVersion = meta.version;
        const currentVersion = localStorage.getItem("appVersion");

        const shouldForceRefresh = semverGreaterThan(
          latestVersion,
          currentVersion
        );
        if (shouldForceRefresh) {
          console.log(
            `We have a new version - ${latestVersion}. Should force refresh`
          );
          this.setState({
            loading: false,
            isLatestVersion: false,
            version: latestVersion,
          });
        } else {
          console.log(
            `You already have the latest version - ${latestVersion}. No cache refresh needed.`
          );
          this.setState({ loading: false, isLatestVersion: true });
        }
      });
  }
  render() {
    const { loading, isLatestVersion, refreshCacheAndReload, version } =
      this.state;
    return this.props.children({
      loading,
      isLatestVersion,
      refreshCacheAndReload,
      version,
    });
  }
}

export default CacheBuster;
