import React, { Component } from "react";
import Footer from "../footer/footer";
import BookedSession from "./bookedSessions";
import PreviousSessions from "./PreviousSessions";
import helper from "../helper";

class MySessions extends Component {
  state = {
    sessions: [],
    children: [],
    response: false,
    tab: "booked-sessions",
    id: "",
  };
  componentWillMount() {
    this.fetchChildren();
    const {
      match: { params },
    } = this.props;
    const { sessions_type, id } = params;
    if (sessions_type) {
      this.setState({
        tab: sessions_type,
      });
    }
    if (sessions_type && id) {
      this.setState({
        tab: sessions_type,
        id,
      });
    }
  }
  getSessions = async (qs, screenOffset) => {
    this.setState({
      sessions: [],
      response: false,
    });
    const query = `{
      sessions(${qs}){
        _id,
        child{
          first_name,
          last_name,
          previousSessions,
          avatar{
            url
          }
        },
        activity{
          name,
          description,
          ageGroup{
            min
          },
          category{
            name
          }
        },
        center{
          name,
          address,
          timezone,
        },
        dropTime,
        pickupTime,
        start,
        end,
        media{url},
      }
    }`;
    const data = await helper.apolloQuery(query);

    if (data.sessions) {
      const { sessions } = data;
      await this.setState({
        sessions,
        response: true,
      });
    }

    await this.scrollWindow(screenOffset);
  };

  scrollWindow = (offSet) => {
    const screenOffSet = offSet ? offSet : { x: 0, y: 0 };
    const { x, y } = screenOffSet;
    window.scrollTo({
      top: y,
      left: x,
      behavior: "smooth",
    });
  };

  fetchChildren = async () => {
    const parent_id = localStorage.getItem("parent_id");
    const query = `{
      children(parent_id:"${parent_id}"){
        first_name,
        last_name,
        _id,
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { children } = data;
    await this.setState({
      children,
      response: true,
    });
  };

  checkChildren(children) {
    return children && children.length ? true : false;
  }

  handleTab = (tab) => {
    this.setState({
      tab,
      id: tab === "previous-sessions" ? 0 : this.state.id,
    });
    this.props.history.push({
      pathname: `/sessions/${tab}`,
    });
  };

  render() {
    const { sessions, response, children, tab, id } = this.state;
    const hasChildren = this.checkChildren(children);
    return (
      <div className="my-sessions">
        <div className="tabs">
          <div
            className={tab === "booked-sessions" ? "tab active" : "tab"}
            onClick={() => this.handleTab("booked-sessions")}
          >
            <p>Booked Sessions</p>
          </div>
          <div
            className={tab === "previous-sessions" ? "tab active" : "tab"}
            onClick={() => this.handleTab("previous-sessions")}
          >
            <p>Previous Sessions</p>
          </div>
        </div>
        {tab === "booked-sessions" && (
          <BookedSession
            response={response}
            sessions={sessions}
            getSessions={this.getSessions}
            hasChildren={hasChildren}
          />
        )}
        {tab === "previous-sessions" && (
          <PreviousSessions
            response={response}
            sessions={sessions}
            getSessions={this.getSessions}
            hasChildren={hasChildren}
            id={id}
          />
        )}
        {/* <TabView>
          <p>Booked Sessions</p>
          <span>
            <BookedSession response={response} sessions={sessions} getSessions={this.getSessions} hasChildren={hasChildren} />
          </span>
          <p>Previous Sessions</p>
          <span>
            <PreviousSessions response={response} sessions={sessions} getSessions={this.getSessions} hasChildren={hasChildren} />
          </span>
        </TabView> */}
        <Footer currentPage="sessions" />
      </div>
    );
  }
}

export default MySessions;
