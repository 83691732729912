import React, { Component } from 'react';
import { connect } from "react-redux";
import moment from "moment";
import 'moment-timezone';
import OtpInput from 'react-otp-input';
import { Helmet } from 'react-helmet';
import helper from './helper';
import SpinnerModal from './modal/SpinnerModal';
import { withRouter } from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';
import { baseUrl } from './url';

class SignInCode extends Component {
  state = {
    code: '',
    makeResquest: false,
    userType: ''
  }
  componentDidMount(){
    const userType = localStorage.getItem('user_type');
    this.setState({
      userType,
    })
  }
  handleOtp = (code) => {
    this.setState({
      code,
      codeError: '',
      login: false,
      makeResquest: false,
    })
  }

  verify = () => {
    this.setState({
      makeResquest: true,
    })
    const { code, userType } = this.state;
    const { location } = (this.props);
    const { username } = location;
    let userUrl;
    if(userType==='parent'){
      userUrl = 'w';
    }else if(userType==='educator'){
      userUrl = 'e';
    }
    fetch(`${baseUrl}/${userUrl}/auth/login`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        code,
        username,
      })
    })
      .then((response) => {
        this.setState({
          makeResquest: false,
        })
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }

        // const e = new Error('Invalid status, status code: ' + response.status)
        // e.status = response.status;
        throw response;
      })
      .then((responseJson) => {
        console.log(responseJson);
        const { token, _id } = responseJson;
        const user = userType==='parent'?'parent_id':'educator_id';
        const date = moment();
        localStorage.setItem('access_token', token);
        localStorage.setItem('token_time', date);
        localStorage.setItem(user, _id);
        this.setUserType(userType);
        this.redirect();
      })
      .catch((error) => {
        console.error(error);
        error.json().then(e => {
          console.log(e);
          this.setState({
                codeError: e.message
              })
        })
        // if (error.status === 401) {
        //   this.setState({
        //     codeError: 'The code you enter is invalid'
        //   })
        // }
      });
  }

  edit = () => {
    this.props.history.goBack()
  }

  redirect = () => {
    const { userType } = this.state;
    userType==='parent' && this.props.history.push({
      pathname: '/sessions/booked-sessions',
    })
    userType==='educator' && this.props.history.push({
      pathname: '/educator/sessions/new',
    })
  }

  setUserType = (userType) => {
    this.props.dispatch({ 
      type: "SETUSERTYPE",
      payload: userType
    });
  };

  render() {
    const { code, codeError, makeResquest } = this.state;
    const { location } = (this.props);
    const { username } = location;
    // const { from } = this.props.location.state || { from: { pathname: '/' } }
    // const token = localStorage.getItem('access_token');
    // if (token) {
    //   return <Redirect to={from} />;
    // }
    return (
      <div className="auth-container">
        <div className="cover-cart"></div>
        <Helmet>
          <style>{'body, html { background-color: #FFFFFF; }'}</style>
        </Helmet>
        <div className='signup-title-div'>
          <p className='auth-title'>Please enter code sent to</p>
        </div>
        <div className='sent-code-num-div'>
          <i className="fa fa-pencil edit" onClick={this.edit}></i>
          <p className='text'>{username}</p>
        </div>
        <label>
          <OtpInput
            onChange={otp => this.handleOtp(otp)}
            numInputs={4}
            inputStyle={'otp-input'}
            containerStyle={'otp-container'}
          />
          <p className="error-text center">{codeError}</p>
        </label>
        <div className='signup-button-div'>
          <button className="green-button" disabled={code.length < 4} onClick={this.verify}>CONTINUE</button>
        </div>
        <div className='receiveth-not-div'>
          <p className='text'><span className='auth-link-text'>I didn’t receive a code</span></p>
        </div>
        <div className='terms-div'>
          <p className='text'>Tap Continue to accept our <strong className='black'>Terms</strong> and <strong className='black'>privacy policy</strong></p>
        </div>
        <SpinnerModal show={makeResquest} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    userType: state.userType
  };
}

// export default withRouter(BookSession);
export default connect(mapStateToProps)(withRouter(SignInCode));
// export default withRouter(SignInCode);