import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import helper from "./helper";
import SpinnerModal from "./modal/SpinnerModal";
import check from "../assets/svg/check-white.svg";
import { withRouter, Redirect } from "react-router-dom";
import { baseUrl } from "./url";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

class SignIn extends Component {
  state = {
    username: "",
    makeResquest: false,
    educatorSignin: false,
  };

  handlePhoneNumber = (isValid, value, countryData, number) => {
    const phone_no = number.replace(/\s/g, "");
    this.setState({
      username: phone_no,
      usernameError: "",
    });
  };

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };

  loginRequest = () => {
    const { username, educatorSignin } = this.state;
    const error = helper.checkUsername(username);
    if (error) {
      const { usernameError } = error;
      this.setState({
        usernameError,
      });
    }
    if (!error) {
      this.setState({
        makeResquest: true,
      });
      let uriType;
      if (educatorSignin) {
        uriType = "e";
        localStorage.setItem("user_type", "educator");
      } else {
        uriType = "w";
        localStorage.setItem("user_type", "parent");
      }
      fetch(`${baseUrl}/${uriType}/auth/login/request`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          username,
        }),
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          });
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }
          throw response;
        })
        .then(() => {
          this.verifyPage();
        })
        .catch((error) => {
          console.error(error);
          this.setState({
            usernameError: error.message,
          });
        });
    }
  };

  //   window.open(`${baseUrl}/auth/google`, "_blank");
  //   // fetch(`${baseUrl}/auth/google`, {
  //   //   method: "GET",
  //   //   headers: {
  //   //     "Content-Type": "application/json",
  //   //   },
  //   //   credentials: "same-origin",
  //   // })
  //   //   .then((response) => {
  //   //     console.log(response);
  //   //     this.setState({
  //   //       makeResquest: false,
  //   //     });
  //   //     return response.json();
  //   //   })
  //   //   .then(() => {
  //   //     this.verifyPage();
  //   //   })
  //   //   .catch((error) => {
  //   //     console.error(error);
  //   //   });
  // };

  verifyPage = () => {
    const { username } = this.state;
    this.props.history.push({
      pathname: "/signin-verification",
      username,
    });
  };

  handleEducatorSignin = () => {
    const { educatorSignin } = this.state;
    this.setState({
      educatorSignin: !educatorSignin,
    });
  };

  render() {
    const { usernameError, makeResquest, educatorSignin, username } =
      this.state;
    const { location } = this.props;
    const { from } = location.state || { from: { pathname: "/" } };
    const token = localStorage.getItem("access_token");
    if (location.state) {
      localStorage.setItem("from", JSON.stringify(location.state.from));
    }
    if (educatorSignin) {
      localStorage.removeItem("from");
    }
    if (token) {
      return <Redirect to={from} />;
    }
    return (
      <React.Fragment>
        <div className="auth-container">
          <div className="cover-cart"></div>
          <Helmet>
            <style>{"body, html { background-color: #FFFFFF; }"}</style>
          </Helmet>
          <div className="signin-title-div">
            <p className="auth-title">{educatorSignin && "EDUCATOR "}SIGN IN</p>
          </div>
          <div className="signin-text-div">
            <p className="text">
              Enter your email below and we’ll send you a verification code to
              log you in.
            </p>
          </div>
          {/* <div className="label"> */}
          {/* <IntlTelInput
              preferredCountries={["ng", "rw", "ke", "ug"]}
              separateDialCode={true}
              useMobileFullscreenDropdown={true}
              formatOnInit={false}
              onPhoneNumberChange={this.handlePhoneNumber}
            /> */}
          <div className="signin-email">
            <label>Email</label>
            <input
              type="email"
              name="username"
              value={username}
              onChange={this.handleChange}
            />
            <p className="error-text">{usernameError}</p>
          </div>

          {/* </div> */}
          <div className="signin-button-div">
            <button
              className="green-button"
              onClick={() => this.loginRequest()}
            >
              LOGIN
            </button>
          </div>
          {!educatorSignin && (
            <div className="signin-reg-div">
              <p className="text">
                Don’t have an acount?{" "}
                <span className="auth-link-text">
                  <Link
                    to="/signup"
                    style={{ textDecoration: "none", color: "#FFA300" }}
                  >
                    Register here
                  </Link>
                </span>
              </p>
            </div>
          )}
          <SpinnerModal show={makeResquest} />
        </div>
        <div
          className={
            educatorSignin
              ? "educator-login-check checked"
              : "educator-login-check"
          }
          onClick={this.handleEducatorSignin}
        >
          {educatorSignin && <img src={check} alt="" />}
          Signin as Educator
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(SignIn);
