import React, { Component } from "react";
import Footer from "../footer/footer";
import TabView from "../TabView/TabView";
import Member from "./Member";
import Package from "./Package";
import helper from "../helper";
import RavePaymentModal, {
  RavePaymentButton,
  RaveProvider,
} from "react-ravepayment";
import moment from "moment";
import "moment-timezone";
import { baseUrl } from "../url";

class MemberShip extends Component {
  state = {
    type: "",
    selectedPlan: "",
    packages: "",
    key: process.env.REACT_APP_RAVE_PUBLIC_KEY, // RavePay PUBLIC KEY
    email: "", // customer email
    amount: 0,
  };

  componentDidMount() {
    this.fetchEmail();
  }

  fetchEmail = async () => {
    const query = `{
      user{
        email
      }
    }`;
    const data = await helper.apolloQuery(query);
    await this.setState({
      email: data.user.email,
    });
  };

  getType = (type) => {
    this.setState({
      type,
    });
  };

  getPlan = (selectedPlan, amount, children_id) => {
    this.setState({
      selectedPlan,
      amount: Number(amount),
      children_id,
    });
  };

  callback = async (response) => {
    if (response.success === true) {
      const token = await `Bearer ${await helper.verifyToken()}`;
      await fetch(`${baseUrl}/w/subscriptions/checkout`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          package: this.state.selectedPlan,
          txref: response.tx.txRef,
          flwref: response.tx.flwRef,
          children: this.state.children_id,
          start: moment().format("YYYY-MM-DD"),
        }),
      })
        .then((response) => {
          console.log(response.json());
          this.props.history.push({
            pathname: "/profile",
          });
        })
        .catch((error) => {
          console.error(error);
        });
    }
    console.log(response.data.data.status);
  };

  close = () => {
    console.log("Payment closed");
  };

  getReference = () => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  render() {
    const { selectedPlan, email, amount, key } = this.state;
    return (
      <div className="membership">
        <TabView>
          <p>Membership</p>
          <span>
            <Member
              getType={this.getType}
              getPlan={this.getPlan}
              selectedPlan={selectedPlan}
            />
          </span>
          <p>Packages</p>
          <span>
            <Package
              getType={this.getType}
              getPlan={this.getPlan}
              selectedPlan={selectedPlan}
            />
          </span>
        </TabView>
        {amount > 0 && (
          <div className="btn-div">
            <div className="total">
              <p className="text key">Total</p>
              <p className="text value">₦{amount}</p>
            </div>
            <RaveProvider
              customer_email={email}
              amount={amount}
              PBFPubKey={key}
              txref="rave-123456"
              production={process.env.NODE_ENV === "production"}
              onSuccess={this.callback}
              onClose={this.close}
            >
              <RavePaymentButton class="green-button">
                CHECKOUT
              </RavePaymentButton>
            </RaveProvider>
            {/* <RavePaymentModal
              text="MAKE PAYMENT"
              class="green-button"
              reference={this.getReference()}
              email={email}
              amount={amount}
              ravePubKey={key}
              callback={this.callback}
              close={this.close}
              isProduction={false}
              tag="button"
            /> */}
          </div>
        )}
        <Footer currentPage="profile" />
      </div>
    );
  }
}

export default MemberShip;
