import React, { Component } from 'react'
import { Helmet } from 'react-helmet';
import { withRouter, Redirect, Link } from 'react-router-dom';
import SpinnerModal from './modal/SpinnerModal';
import Helper from './helper';
import { baseUrl } from './url';
import IntlTelInput from 'react-intl-tel-input';

import 'react-intl-tel-input/dist/main.css';

class SignUp extends Component {
  state = {
    username: '',
    first_name: '',
    last_ame: '',
    email: '',
    phone_no: '',
    country: '',
    makeResquest: false,
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: '',
    })
    if (e.target.name === 'email' || e.target.name === 'phone_no') {
      this.setState({
        formError: '',
      })
    }
  }
  handlePhoneNumber = (isValid, value, countryData, number) => {
    const { name } = countryData;
    const phone_no = number.replace(/\s/g, '');
    this.setState({
      phone_no,
      country: name,
      formError: ''
    })
  }

  signupRequest = () => {
    const { email, first_name, last_name, phone_no, country } = this.state;
    const errors = Helper.validate(email, first_name, last_name, phone_no);
    if (errors) {
      const { first_nameError, last_nameError, emailError, phone_noError } = errors;
      this.setState({
        first_nameError,
        last_nameError,
        emailError,
        phone_noError,
      })
    }
    if (!errors) {
      this.setState({
        makeResquest: true,
      })
      fetch(`${baseUrl}/w/auth/register`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          first_name,
          last_name,
          phone_no,
          country,
        })
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          })
          if (Helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }
          throw response;
        })
        .then((responseJson) => {
          const { parent_id, token } = responseJson;
          localStorage.setItem('access_token', token);
          localStorage.setItem('parent_id', parent_id);
          this.verifyPage();
        })
        .catch((error) => {
          error.json().then(e => {
            console.log(e);
            if (e) {
              this.setState({
                formError: e.message || "Profile already exists with phone number or email entered"
              })
            }
          })
        });
    }
  }

  verifyPage = () => {
    const { phone_no } = this.state;
    this.props.history.push({
      pathname: '/signup-verification',
      phone_no,
    })
  }


  render() {
    const { first_nameError, last_nameError, emailError, phone_noError, formError, makeResquest } = this.state;
    const { from } = this.props.location.state || { from: { pathname: '/' } }
    const token = localStorage.getItem('access_token');
    if (token) {
      return <Redirect to={from} />;
    }
    return (
      <div className="auth-container">
        <div className="cover-cart"></div>
        <Helmet>
          <style>{'body, html { background-color: #FFFFFF; }'}</style>
        </Helmet>
        <div className='signup-title-div'>
          <p className='auth-title'>SIGN UP</p>
        </div>
        <div>
          <label>
            <input className='signup-input' type="text" placeholder="First name" name="first_name" onChange={this.handleChange} />
            <p className="error-text">{first_nameError}</p>
          </label>
          <label>
            <input className='signup-input' type="text" placeholder="Last name" name="last_name" onChange={this.handleChange} />
            <p className="error-text">{last_nameError}</p>
          </label>
          <label>
            <input className='signup-input' type="text" placeholder="Email address" name="email" onChange={this.handleChange} />
            <p className="error-text">{emailError}</p>
          </label>
          <div className="label">
            <IntlTelInput
              preferredCountries={['ng', 'rw', 'ke', 'ug']}
              separateDialCode={true}
              useMobileFullscreenDropdown={true}
              formatOnInit={false}
              onPhoneNumberChange={this.handlePhoneNumber}
            />
            <p className="error-text">{phone_noError}</p>
          </div>
          <label>
            <input className='signup-input' type="text" placeholder="Country" name="country" defaultValue={this.state.country} />
          </label>
        </div>
        <div className='signup-terms'>
          <p className='text'>By continuing you agree to STEM Cafe’s <span className='auth-link-text'>Terms &amp; Conditon</span> and <span className='auth-link-text'>privacy policy</span></p>
          <p className='text'>Already have an acount? <span className='auth-link-text'><Link to="/signin" style={{ textDecoration: 'none', color: '#FFA300' }}>Login here</Link></span></p>
        </div>
        <label>
          <p className="error-text center">{formError}</p>
        </label>
        <div className='signup-button-div'>
          <button className="green-button" onClick={this.signupRequest}>CREATE ACCOUNT</button>
        </div>
        <SpinnerModal show={makeResquest} />
      </div>
    )
  }
}
export default withRouter(SignUp);