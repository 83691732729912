import React, { Component } from 'react';
import moment from "moment";
import 'moment-timezone';
import OtpInput from 'react-otp-input';
import {Helmet} from 'react-helmet';
import helper from './helper';
import SpinnerModal from './modal/SpinnerModal';
import { withRouter } from 'react-router-dom';
import { baseUrl } from './url';

class SignUpCode extends Component {
  state = {
    code: '',
  }
  handleOtp = (code) => {
    this.setState({
      code,
      makeResquest: false,
    })
  }

  verify = () => {
    this.setState({
      makeResquest: true,
    })
    const { code } = this.state;
    const { location } = (this.props);
    const { phone_no } = location;
    fetch(`${baseUrl}/w/auth/login`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          code,
          username: phone_no,
        })
      })
      .then((response) => {
        this.setState({
          makeResquest: false,
        })
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }

        const e = new Error('Invalid status, status code: '+ response.status )
        e.status = response.status;
        throw e;
      })
      .then((responseJson) => {
        const { _id, token } = responseJson;
        const date = moment();
        localStorage.setItem('access_token', token);
        localStorage.setItem('token_time', date);
        localStorage.setItem('parent_id', _id);
        this.redirect();
      })
      .catch((error) => {
        console.error(error);
        if(error.status === 401){
          this.setState({
            codeError: 'The code you enter is invalid'
          })
        }
      });
  }

  redirect = () => {
    this.props.history.push({
      pathname: '/sessions',
  })
  }

  render() {
    const { code, makeResquest } = this.state;
    return (
      <div className="auth-container">
        <div className="cover-cart"></div>
        <Helmet>
            <style>{'body, html { background-color: #FFFFFF; }'}</style>
        </Helmet>
        <div className='signup-title-div'>
          <p className='auth-title'>Enter your code</p>
        </div>
        <div className='sent-code-text-div'>
            <p className='text'>We have sent a code to your phone number. Enter the code below so we can verify your identity.</p>
        </div>
        <div>
            <OtpInput
              onChange={otp => this.handleOtp(otp)}
              numInputs={4}
              inputStyle={'otp-input'}
              containerStyle={'otp-container'}
            />
        </div>
        <div className='signup-button-div'>
          <button className="green-button" disabled={code.length < 4} onClick={this.verify}>CONTINUE</button>
        </div>
        <div className='receiveth-not-div'>
            <p className='text'><span className='auth-link-text'>I didn’t receive a code</span></p>
        </div>
        <div className='terms-div'>
            <p className='text'>Tap Continue to accept our <strong className='black'>Terms</strong> and <strong className='black'>privacy policy</strong></p>
        </div>
        <SpinnerModal show={makeResquest} />
      </div>
    )
  }
}
export default withRouter(SignUpCode);