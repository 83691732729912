import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import { connect } from "react-redux";
import Footer from "../footer/footer";
import Top from "../top/Top";
import CartBig from "../../assets/svg/cartbig.svg";
// import description from '../../assets/svg/description.svg';
import spinner from "../../assets/svg/spinner.svg";
import SpinnerModal from "../modal/SpinnerModal";
import { Link, withRouter } from "react-router-dom";
import helper from "../helper";
// import {Helmet} from 'react-helmet';
import { RavePaymentButton, RaveProvider } from "react-ravepayment";
import { baseUrl } from "../url";

class Cart extends Component {
  state = {
    cart: [],
    discount: false,
    total: 0,
    key: process.env.REACT_APP_RAVE_PUBLIC_KEY,
    email: "", // customer email
    makeResquest: false,
  };

  componentDidMount() {
    this.fetchCart();
    this.getCount();
    this.fetchEmail();
    setInterval(() => {
      this.getCount();
    }, 120000);
  }

  fetchEmail = async () => {
    const query = `{
      user{
        email
      }
    }`;
    const data = await helper.apolloQuery(query);
    await this.setState({
      email: data.user.email,
    });
  };

  fetchCart = async () => {
    const parent_id = localStorage.getItem("parent_id");
    const query = `{
      carts(parent_id:"${parent_id}"){
        _id,
        child{
          first_name,
          last_name
        },
        activity{
          name
        },
        center{
          name
        },
        start,
        end,
        cost,
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { carts } = data;
    await this.setState(
      {
        cart: carts,
        response: true,
      },
      () => {
        this.calculateTotal();
      }
    );
  };

  calculateTotal = async () => {
    const { cart } = this.state;

    const cart_count = cart.length;
    localStorage.setItem("cart_count", cart_count);

    let total = 0;
    await cart.forEach((cartItem) => {
      let cost = parseInt(cartItem.cost);
      total = total + cost;
    });
    await this.setState({
      total,
    });
    this.getCount();
  };

  getCount = () => {
    this.props.dispatch({ type: "GETCOUNT" });
  };

  callback = async (response) => {
    // console.log(response);
    console.log(response.tx.txRef);
    // console.log(`flwref: ${response.tx.flwRef}`);
    if (response.data.data.status === "successful") {
      const token = await `Bearer ${await helper.verifyToken()}`;
      await fetch(`${baseUrl}/w/sessions/checkout`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: token,
        },
        body: JSON.stringify({
          txref: response.tx.txRef,
          // flwref: response.tx.flwRef
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((resjson) => {
          console.log(resjson);
          this.fetchCart();
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // console.log(response.data.tx.txRef);
    console.log(response.data.data.status);
  };

  close = () => {
    console.log("Payment closed");
    this.props.history.push({
      pathname: "/sessions",
    });
  };

  getReference = () => {
    let text = "";
    let possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-.=";

    for (let i = 0; i < 10; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  };

  removeFromCart = async (id) => {
    this.setState({
      makeResquest: true,
    });
    const token = await `Bearer ${await helper.verifyToken()}`;
    await fetch(`${baseUrl}/w/sessions/cart/remove/${id}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
    })
      .then((response) => {
        this.setState({
          makeResquest: false,
        });
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }

        const e = new Error("Invalid status, status code: " + response.status);
        e.status = response.status;
        e.response = response;
        throw e;
      })
      .then((responseJson) => {
        this.fetchCart();
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response.json());
        if (error.status === 401) {
          // this.setState({
          //   codeError: 'The code you enter is invalid'
          // })
        }
      });
  };

  render() {
    const { total, discount, cart, response, key, email, makeResquest } =
      this.state;

    return (
      <div className="cart">
        <Top title="My Cart" />
        {!response && (
          <div className="spinner-div">
            <img src={spinner} alt="Loading" width="70" />
          </div>
        )}
        {cart.length === 0 && response && (
          <div className="cart-empty">
            <img className="cart-img" src={CartBig} alt="" />
            <p className="text">Your cart is empty</p>
            {/* <button className="green-button">BOOK SESSION</button> */}
            <Link to="/book-session" style={{ textDecoration: "none" }}>
              <button className="green-button">BOOK SESSION</button>
            </Link>
          </div>
        )}
        {cart.length > 0 && response && (
          <div className="cart-full">
            {cart.map((cartItem) => {
              const { _id, activity, child, cost, start, end } = cartItem;
              const { first_name, last_name } = child;
              const badge = helper.createBadge(first_name, last_name);
              const date = moment(start, "x").format("ddd DD/MM/YYYY");
              const time = moment(start, "x").format("hh:mma");
              const startTime = moment(start, "x");
              const endTime = moment(end, "x");
              const duration = endTime.diff(startTime, "hours");
              let activityName;
              if (activity) {
                activityName = activity.name;
              }
              return (
                <div className="cart-not-empty" key={_id}>
                  <div className="name-container">
                    <div className="badge">{badge}</div>
                    <div>
                      <p className="text name">{first_name}</p>
                      <p className="text details">
                        {activity ? activityName : `${duration} Hours in cart`}
                      </p>
                    </div>
                  </div>
                  <div className="cart-details-duration">
                    <div className="details-top">
                      <p className="text">{duration} Hours in cart</p>
                      <button
                        className="orange-button"
                        onClick={() => this.removeFromCart(_id)}
                      >
                        REMOVE
                      </button>
                    </div>
                    <div className="details-main">
                      <div className="details-row">
                        <p className="text key">DATE</p>
                        <p className="text value">{date}</p>
                      </div>
                      <div className="details-row">
                        <p className="text key">TIME</p>
                        <p className="text value">{time}</p>
                      </div>
                      <div className="details-row">
                        <p className="text key">PRICE</p>
                        <p className="text value">₦{cost}</p>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="checkout">
              {discount && (
                <div className="discount">
                  <div className="discount-div">
                    <p className="text sub-key">Sub-total</p>
                    <p className="text sub-value">₦4000</p>
                  </div>
                  <div className="discount-div">
                    <p className="text discount-key">Discount (10%)</p>
                    <p className="text discount-value">₦400</p>
                  </div>
                </div>
              )}
              <div className="total">
                <p className="text key">Total</p>
                <p className="text value">₦{total}</p>
              </div>
              <RaveProvider
                customer_email={email}
                PBFPubKey={key}
                txref="rave-123456"
                production={process.env.NODE_ENV === "production"}
                onSuccess={this.callback}
                onClose={this.close}
                amount={total}
              >
                <RavePaymentButton class="green-button">
                  CHECKOUT
                </RavePaymentButton>
              </RaveProvider>
              {/* <RavePaymentModal
                text="CHECKOUT"
                class="green-button"
                // metadata={[{metaname:'Device', metavalue : 'IPhone X'}]}
                reference={this.getReference()}
                email={email}
                // currency={'NGN'}
                amount={total}
                ravePubKey={key}
                callback={this.callback}
                close={this.close}
                isProduction={process.env.NODE_ENV === "production"}
                tag="button"
              /> */}
            </div>
          </div>
        )}
        <SpinnerModal show={makeResquest} />
        <Footer />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    count: state.count,
  };
}

// export default Cart;
export default connect(mapStateToProps)(withRouter(Cart));
