import moment from "moment-timezone";
import "moment-timezone";
import imageCompression from "browser-image-compression";
import { createApolloFetch } from "apollo-fetch";
import { baseUrl } from "./url";

const uri = `${baseUrl}/query`;
const apolloFetch = createApolloFetch({ uri });

export default class helper {
  static validate(email, first_name, last_name, phone_no, country) {
    const first_nameError = !first_name ? "Please Enter your first name" : null;
    const last_nameError = !last_name ? "Please Enter your last name" : null;
    const emailError = !email ? "Please Enter a valid email address" : null;
    const phone_noError = !phone_no
      ? "Please Enter a valid phone number"
      : null;
    // const countryError = !country? 'Please select a country from the list' : null;

    if (email && first_name && last_name && phone_no) {
      return null;
    }
    return {
      first_nameError,
      last_nameError,
      emailError,
      phone_noError,
    };
  }

  static validateChild(
    first_name,
    last_name,
    gender,
    dob,
    school,
    special_needs
  ) {
    const first_nameError = !first_name ? "Please Enter your first name" : null;
    const last_nameError = !last_name ? "Please Enter your last name" : null;
    const genderError = !gender ? "Please select a gender" : null;
    const dobError = !dob ? "Please Enter a date of birth" : null;
    // const schoolError = !school ? 'Please Enter a school name' : null;
    // const special_needsError = !special_needs ? 'Please enter NONE if no special needs' : null;

    if (first_name && last_name && gender && dob) {
      return null;
    }
    return {
      first_nameError,
      last_nameError,
      genderError,
      dobError,
    };
  }

  static checkUsername(username) {
    const usernameError = !username ? "Please enter your phone number" : null;
    if (username) {
      return null;
    }
    return {
      usernameError,
    };
  }

  static validateChildInfo(child, center) {
    const childError = !child ? "Please pick a child" : null;
    const centerError = !center ? "Please pick a center" : null;
    if (child && center) {
      return null;
    }
    return {
      childError,
      centerError,
    };
  }

  static validateContact(first_name, last_name, phone_no, relationship) {
    const first_nameError = !first_name ? "Please Enter a first name" : null;
    const last_nameError = !last_name ? "Please Enter a last name" : null;
    const relationshipError = !relationship
      ? "Please Enter relationship of contact"
      : null;
    const phone_noError = !phone_no
      ? "Please Enter a valid phone number"
      : null;

    if (relationship && first_name && last_name && phone_no) {
      return null;
    }
    return {
      first_nameError,
      last_nameError,
      relationshipError,
      phone_noError,
    };
  }

  static range(start, end, interval) {
    let gen = [];

    if (typeof start !== "number" || typeof end !== "number") {
      return gen;
    }

    interval = typeof interval === "number" ? interval : 1;

    for (let i = start; i <= end; i += interval) {
      gen.push(i);
    }

    return gen;
  }

  static logout() {
    localStorage.clear();
    window.location.reload();
  }

  static createBadge(first_name, last_name) {
    const name = `${first_name} ${last_name}`;
    let matches = name.match(/\b(\w)/g);
    return matches.join("").toUpperCase();
  }

  static async verifyToken() {
    const tokenTime = localStorage.getItem("token_time");
    const now = moment();
    const hoursPassed = moment.duration(now.diff(tokenTime)).get("hours");
    const daysPassed = moment.duration(now.diff(tokenTime)).get("days");

    if (daysPassed >= 14) {
      this.logout();

      return null;
    }

    if (hoursPassed >= 2) {
      await helper.refreshToken();
    }

    let token = localStorage.getItem("access_token");

    return token;
  }

  static async refreshToken() {
    const token = localStorage.getItem("access_token");
    let response;

    try {
      response = await fetch(`${baseUrl}/auth/token/renew`, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    } catch (e) {
      console.log(e);
      return;
    }

    if (~helper.range(200, 299).indexOf(response.status)) {
      const { token } = await response.json();
      const date = moment();
      localStorage.setItem("access_token", token);
      localStorage.setItem("token_time", date);
    }

    if (response.status === 401) {
      this.logout();
    }
  }

  static async apolloQuery(query) {
    const token = await `Bearer ${await this.verifyToken()}`;

    apolloFetch.use(({ request, options }, next) => {
      if (!options.headers) {
        options.headers = {};
      }
      options.headers["authorization"] = token;

      next();
    });

    const response = await apolloFetch({ query });
    const { data } = response;
    console.log(response);
    // console.log(response.data);
    // .then(result => {
    //   const { data, /* errors, extensions */ } = result;
    //   console.log(data);
    /**
     * Did this work?
     * yes
     * Ok let me try something.
     * Try that.
     * Let me know what the response logs
     * check slack
     * Try the new update
     *
     */
    // })
    // .catch(error => {
    //   console.log(error);
    // });

    return data;
  }

  static compressImage(file, size) {
    // var imageFile = event.target.files[0];
    const imageFile = file;
    // console.log(imageFile.type);
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);
    if (imageFile.size < 500000) return imageFile;

    const options = {
      maxSizeMB: size,
      // maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    return imageCompression(imageFile, options)
      .then((compressedFile) => {
        // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
        // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB
        return new File([compressedFile], imageFile.name, {
          type: imageFile.type,
        }); // write your own logic
      })
      .catch((error) => {
        console.log(error.message);
      });
  }

  static blobToFile(theBlob, fileName) {
    //A Blob() is almost a File() - it's just missing the two properties below which we will add
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  static async asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
      await callback(array[index], index, array);
    }
  }

  static async compressMedia(media, size) {
    let compressedMedia = [];
    await this.asyncForEach(media, async (file) => {
      const image = await this.compressImage(file, size);
      compressedMedia = [...compressedMedia, image || file];
    });
    return compressedMedia;
  }

  static formatDateTime(date, format, timezone) {
    let formattedDate;
    if (timezone) {
      formattedDate = moment(date, "x").tz(timezone).format(format);
    } else {
      formattedDate = moment(date, "x").format(format);
    }
    return formattedDate;
  }
}
