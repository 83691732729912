import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom';
import noSession from '../../assets/svg/no-sessions.svg';
import noChild from '../../assets/svg/no-child-added.svg';
import spinner from '../../assets/svg/spinner.svg';
import helper from '../helper';

class PreviousSessions extends Component {
  state = {
    sessions: false,
    details1: false,
    details2: false,
    id: this.props.id || 0,
    token: ''
  }

  async componentWillMount() {
    const token = await helper.verifyToken();
    this.setState({
      token,
    })
  }

  componentDidMount() {
    const parent_id = localStorage.getItem('parent_id');
    const qs = `parent_id:"${parent_id}", status: COMPLETED`;
    const { screenOffset } = this.props.location;
    this.props.getSessions(qs, screenOffset);
  }

  viewDetails1 = () => {
    this.setState({
      details1: !this.state.details1
    })
  }

  viewDetails2 = () => {
    this.setState({
      details2: !this.state.details2
    })
  }

  expand = (id) => {
    this.setState({
      id,
    })
    if (id === 0) {
      this.props.history.push({
        pathname: `/sessions/previous-sessions`,
      })
    } else {
      this.props.history.push({
        pathname: `/sessions/previous-sessions/${id}`,
      })
    }
  }

  seeMore = (id) => {
    const offSetX = window.pageXOffset;
    const offSetY = window.pageYOffset;
    this.props.history.push({
      pathname: `/see-more/${id}`,
      screenOffset: { x: offSetX, y: offSetY },
      prevPath: this.props.location.pathname
    })
  }

  render() {
    const { sessions, response, hasChildren } = this.props;
    const { id, token } = this.state;
    return (
      <div className="previous-sessions">
        {
          !response &&
          <div className="spinner-div">
            <img src={spinner} alt='Loading' width='70' />
          </div>
        }
        {
          sessions.length > 0 && response && hasChildren &&
          <React.Fragment>
            {
              sessions.map(session => {
                const { _id, activity, child, center, start, end } = session;
                const { first_name, last_name, avatar, previousSessions } = child;
                let url;
                if (avatar) {
                  url = avatar.url
                }
                let activity_name;
                let activity_category;
                let age_group;
                if (activity) {
                  activity_name = activity.name;
                  activity_category = activity.category;
                  age_group = activity.ageGroup;
                }
                const { name: center_name, timezone } = center;
                const date = helper.formatDateTime(start, 'ddd DD/MM/YYYY', timezone);
                const time = helper.formatDateTime(start, 'hh:mma', timezone);
                const badge = helper.createBadge(first_name, last_name);
                const duration = (end - start)/60/1000/60;
                const unit = (duration === 1) ? 'Hour' : 'Hours';
                return (
                  <div className='sessions' key={_id}>
                    <div className='session'>
                      <div className='initials-div'>
                        {
                          url ?
                            <div className="img" style={{ backgroundImage: `url(${url}?access_token=${token})` }}></div>
                            :
                            <div className='initials-icon'>{badge}</div>
                        }
                        <div>
                          <p className='text'>{first_name}</p>
                          <p className='mini-text'>Sessions taken • {previousSessions}</p>
                        </div>
                      </div>
                      <div>
                        {
                          id !== _id &&
                          <button className="orange-button" onClick={() => this.expand(_id)}>VIEW DETAILS</button>
                        }
                        {
                          id === _id &&
                          <button className="orange-button grey" onClick={() => this.expand(0)}>CLOSE</button>
                        }
                      </div>
                    </div>
                    {
                      id === _id &&
                      <div>
                        <div className='session-unit'>
                          <div className='session-top'>
                            <div>
                              <p className='text'>{activity_name? activity_name : `${duration} ${unit} booked`}</p>
                              <p className='mini-text'>Duration • {duration} {unit}</p>
                            </div>
                            <div>
                              <button className="orange-button" onClick={() => this.seeMore(_id)}>SEE MORE</button>
                            </div>
                          </div>
                          <div className='detail-btns'>
                            {
                              activity_category &&
                              <div className='detail-btn'>
                                <p className='text'>{activity_category.name}</p>
                              </div>
                            }
                            {
                              age_group &&
                              <div className='detail-btn'>
                                <p className='text'>{age_group.min}+</p>
                              </div>
                            }
                            <div className='detail-btn'>
                              <p className='text'>{center_name}</p>
                            </div>
                          </div>
                          <div className='info'>
                            <div className='date'>
                              <p className="text">Date taken</p>
                              <p className="text-title">{date}</p>
                            </div>
                            <div className='time'>
                              <p className="text">Time</p>
                              <p className="text-title">{time}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                  </div>
                )
              })
            }
          </React.Fragment>
        }
        {
          !sessions && response && hasChildren &&
          <div className="no-sessions">
            <img src={noSession} alt="" />
            <p className="text">No previous sessions</p>
            <Link to="/book-session" style={{ textDecoration: 'none' }}>
              <button className="green-button">BOOK SESSION</button>
            </Link>
          </div>
        }
        {
          !hasChildren && response &&
          <div className="no-sessions">
            <img src={noChild} alt="" />
            <p className="text">You don't have any child registered yet. click the button below to get started</p>
            <Link to="/profile" style={{ textDecoration: 'none' }}>
              <button className="green-button">GET STARTED</button>
            </Link>
          </div>
        }
      </div>
    )
  }
}

export default withRouter(PreviousSessions);
