import React, { Component } from 'react';
import AddContact from './AddContact';
import Modal from '../modal/Modal';
import dots from '../../assets/svg/three-dots.svg';
import dropChild from '../../assets/svg/drop-child.svg';
import noContactIcon from '../../assets/svg/no-contact-icon.svg';
import helper from '../helper';
import SpinnerModal from '../modal/SpinnerModal';
import spinner from '../../assets/svg/spinner.svg';
import { baseUrl } from '../url';

class PrimaryContact extends Component {
  state = {
    contacts: null,
    contactForm: false,
    options: false,
    show: false,
    makeResquest: false,
    action: '',
    response: false,
    deleteContact: false,
  }
  async componentWillMount(){
    const token = await helper.verifyToken();
    this.setState({
      token,
    })
  }
  componentDidMount() {
    this.fetchContact();
  }
  fetchContact = async () => {
    const query = `{
      user{
        ...on Parent {
          contacts {
              _id
              first_name
              last_name
              relationship
              phone_no
              avatar{
                url
              }
          }
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    await this.setState({
      contacts: data.user.contacts,
      response: true,
    })
  }
  showContactForm = () => {
    this.setState({
      contactForm: true,
    })
  }
  hideContactForm = () => {
    this.setState({
      contactForm: false,
    }, () => {
      this.toggleOptions();
    })
  }
  toggleOptions = () => {
    this.setState({
      options: !this.state.options,
    })
  }
  addContact = () => {
    this.setState({
      contact: true,
    }, () => {
      this.fetchContact();
    })
  }
  showModal = (action) => {
    this.setState({ show: true, action },() => {
      this.hideContactForm();
      this.addContact();
    });
  };
  hideModal = () => {
    this.setState({ show: false });
  };
  showDeleteModal = (id) => {
    this.setState({
      deleteContact: true,
      contactId: id,
    })
  }
  hideDeleteModal = () => {
    this.setState({
      deleteContact: false,
    })
  }
  deleteContact = async (id) => {
    this.hideDeleteModal();
    this.setState({
      makeResquest: true,
    })
    const token = await `Bearer ${await helper.verifyToken()}`;
    await fetch(`${baseUrl}/w/contacts/${id}`, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token
      }
    })
      .then((response) => {
        this.setState({
          makeResquest: false,
        })
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }

        const e = new Error('Invalid status, status code: ' + response.status)
        e.status = response.status;
        e.response = response;
        throw e;
      })
      .then((responseJson) => {
        this.fetchContact();
        this.toggleOptions();
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response.json());
        if (error.status === 401) {
          // this.setState({
          //   codeError: 'The code you enter is invalid'
          // })
        }
      });
  }
  /**
   * How do you pass the argument to methods
   */
  checkContacts(contacts) {
    return contacts && contacts.length?true:false;
  }
  /** 
   * Try this now.
   */
  render() {
    const { contacts, contactForm, options, show, makeResquest, action, response, token, deleteContact, contactId } = this.state;
    return (
      <div>
        {
          !response &&
          <div className="spinner-div">
            <img src={spinner} alt='Loading' width='70' />
          </div>
        }
        {
          (!this.checkContacts(contacts)) && response &&
          <div className="no-contact">
            <div className="contact-card">
              <img src={noContactIcon} alt="" />
              <p className="text-title">No contact added</p>
              <p className="text">You havn’t added a contact yet.</p>
              <button className="green-button" onClick={this.showContactForm}>ADD CONTACT</button>
            </div>
            {
              !this.checkContacts(contacts) && contactForm &&
              <AddContact cancel={this.hideContactForm} handleSubmit={this.showModal} action="add" />
            }
          </div>
        }
        {
          this.checkContacts(contacts) && response &&
          <div className="primary-contact">
            {
              contacts.map(contact => {
                const { _id, first_name, last_name, relationship, phone_no, avatar } = contact;
                let url;
                let imageUrl;
                if(avatar){
                  url = avatar.url;
                  imageUrl = `${url}?access_token=${token}`;
                }
                const badge = helper.createBadge(first_name, last_name);
                return (
                  <React.Fragment key={_id}>
                  <div className="contact-card">
                  {
                    url?
                    <div className="avatar" style={{backgroundImage:`url(${url}?access_token=${token})`}}></div>
                    :
                    <div className="badge">{badge}</div>
                  }
                    <div className="name-phone">
                      <p className="text-title">{first_name} {last_name}</p>
                      <p className="text">{phone_no}</p>
                    </div>
                    <img src={dots} alt="" className="dots" onClick={this.toggleOptions} />
                    {
                      options &&
                      <div className="options">
                        <div onClick={this.showContactForm}>
                          <p className="text">Edit contact</p>
                        </div>
                        <div onClick={() => this.showDeleteModal(_id)}>
                          <p className="text">Remove contact</p>
                        </div>
                      </div>
                    }
                  </div>
                    {
                      contactForm &&
                      <AddContact
                        cancel={this.hideContactForm}
                        handleSubmit={this.showModal} 
                        action="edit"
                        first_name={first_name}
                        last_name={last_name}
                        phone_no={phone_no}
                        relationship={relationship}
                        imageUrl={imageUrl}
                        id={_id} 
                        toggleOptions={this.toggleOptions}/>
                    }
                  </React.Fragment>
                )
              })
            }
          </div>
        }
        <Modal show={show} handleClose={this.hideModal}>
          <div className="popup">
            <img src={dropChild} alt="" />
            <p className="text">
              {action === "add"&& 'Your contact has been successfully added.'}
              {action === "edit"&& 'Your contact has been successfully updated.'}
            </p>
          </div>
        </Modal>
        <Modal show={deleteContact} handleClose={this.hideDeleteModal}>
          <div className="popup">
            <p className="text">Are you sure you want to remove your primary contact?</p>
            <div className={'modalBtn'}>
              <button className="green-button outline" onClick={this.hideDeleteModal}>NO</button>
              <button className="green-button" onClick={() => this.deleteContact(contactId)}>YES</button>
            </div>
          </div>
        </Modal>
        <SpinnerModal show={makeResquest} />
      </div>
    )
  }
}

export default PrimaryContact;
