import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import { baseUrl } from "../../url";
import helper from "../../helper";
import cameraIcon from "../../../assets/svg/camera.svg";
import closeIcon from "../../../assets/svg/media-icon.svg";
import videoIcon from "../../../assets/svg/video-icon.svg";
import Modal from "../../modal/Modal";
import spinner from "../../../assets/svg/spinner.svg";
import Footer from "../footer/Footer";
import checkIcon from "../../../assets/svg/drop-child.svg";
import SpinnerModal from "../../modal/SpinnerModal";

let playing = true;

class ViewMedia extends Component {
  state = {
    playing: true,
  };
  componentDidMount() {
    const { file } = this.props;
    if (file.type !== "image/jpeg") {
      this.refs.vidRef.play();
    }
  }
  toggleVideo = () => {
    if (playing) {
      this.refs.vidRef.pause();
      playing = false;
    } else {
      this.refs.vidRef.play();
      playing = true;
    }
  };
  render() {
    const { file } = this.props;
    const fileUrl = URL.createObjectURL(file);
    return (
      <div className="view-media">
        {file.type === "image/jpeg" && <img src={fileUrl} alt="" />}
        {file.type !== "image/jpeg" && (
          <video src={fileUrl} ref="vidRef" onClick={this.toggleVideo}></video>
        )}
      </div>
    );
  }
}

class EndSession extends Component {
  toType = (obj) => {
    return {}.toString
      .call(obj)
      .match(/\s([a-zA-Z]+)/)[1]
      .toLowerCase();
  };
  render() {
    const {
      handleComment,
      endSession,
      id,
      request,
      error,
      response,
      handleClose,
      name,
    } = this.props;
    const placeholderText = `Add a comment about ${name}’s performance`;
    return (
      <div className="end-session-modal">
        {!response && (
          <React.Fragment>
            <h3>Add a comment to end this session</h3>
            <textarea
              placeholder={placeholderText}
              onChange={handleComment}
            ></textarea>
            {error && this.toType(error) === this.toType([]) ? (
              error.map((item, index) => {
                const { message } = item;
                return (
                  <p className="error-text" key={index}>
                    {message}
                  </p>
                );
              })
            ) : (
              <p className="error-text">{error && error.message}</p>
            )}
            <div className="button-div">
              <button className="green-button" onClick={() => endSession(id)}>
                Save &amp; End Session
              </button>
            </div>
          </React.Fragment>
        )}
        {response && (
          <React.Fragment>
            <div className="popup">
              <img src={checkIcon} alt="" />
              <p>{response}</p>
              <button className="green-button" onClick={handleClose}>
                Ok
              </button>
            </div>
          </React.Fragment>
        )}
        {request && (
          <div className="spinner-div">
            <img src={spinner} alt="Loading" width="40" />
          </div>
        )}
      </div>
    );
  }
}

export default class ActiveSessions extends Component {
  state = {
    media: [],
    viewMedia: false,
    fileToView: {},
    endModal: false,
    sessions: [],
    sessionId: "",
    messageModal: false,
    response: false,
  };
  componentDidMount() {
    this.getSessions();
  }
  getSessions = async () => {
    const query = `{
      sessions(active: true){
        _id,
        child{
          _id,
          first_name,
          last_name,
          dob,
        },
        activity{
          name,
          description,
          duration{
            time,
            unit
          },
          ageGroup{
            min
          },
        },
        center{
          name,
          address,
          timezone,
        },
        dropTime,
        pickupTime,
        start,
        end,
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { sessions } = data;
    await this.setState({
      sessions,
      response: true,
    });
  };

  handleComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  endSession = (id) => {
    this.setState({
      request: true,
      errorMessage: "",
    });
    const token = `Bearer ${localStorage.getItem("access_token")}`;
    const { comment } = this.state;
    fetch(`${baseUrl}/e/sessions/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        stop: true,
        comment,
      }),
    })
      .then((response) => {
        this.setState({
          request: false,
        });
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
        throw response;
      })
      .then((responseJson) => {
        if (responseJson) {
          this.setState(
            {
              serverMessage: responseJson.message,
              sessionId: "",
              media: [],
            },
            () => {
              this.getSessions();
            }
          );
        }
      })
      .catch((error) => {
        if (error) {
          error.json().then((e) => {
            console.log(e);
            this.setState({
              errorMessage: e,
            });
          });
        }
      });
  };

  uploadMedia = async (id) => {
    this.setState({
      modalText: "uploading...",
      request: true,
      errorMessage: "",
    });
    const { media } = this.state;
    if (media.length > 0) {
      const token = `Bearer ${localStorage.getItem("access_token")}`;
      const formData = new FormData();
      media.forEach((file) => {
        formData.append("media", file);
      });

      fetch(`${baseUrl}/e/sessions/${id}/media`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
          // 'Content-Type': 'application/json',
          Authorization: token,
        },
        credentials: "same-origin",
        body: formData,
      })
        .then((response) => {
          this.setState({
            request: false,
          });
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }
          throw response;
        })
        .then((responseJson) => {
          // console.log(responseJson);
        })
        .catch((error) => {
          if (error) {
            this.setState({
              errorMessage: error.message,
            });
            // error.json().then((e) => {
            //   console.log(e);
            //   this.setState({
            //     errorMessage: e.message,
            //   });
            // });
          }
        });
    }
  };

  handleMedia = async (e) => {
    const media = Array.from(e.target.files);
    const { sessionId } = this.state;
    this.setState({
      modalText: "compressing...",
      request: true,
    });
    const compressedMedia = await helper.compressMedia(media, 0.5);
    if (compressedMedia.length > 0) {
      this.setState(
        {
          request: false,
          media: [...this.state.media, ...compressedMedia],
        },
        () => {
          this.uploadMedia(sessionId);
        }
      );
    }
  };

  removeMedia = (fileRemoved) => {
    const { media } = this.state;
    const newMedia = media.filter((file) => file !== fileRemoved);
    this.setState(
      {
        media: [...newMedia],
      },
      () => {
        if (media.length === 0) {
          this.setState({
            sessionId: "",
          });
        }
      }
    );
  };
  showMediaModal = (file) => {
    this.setState({
      viewMedia: true,
      fileToView: file,
    });
  };
  hideMediaModal = () => {
    this.setState({
      viewMedia: false,
      fileToView: {},
    });
  };
  showEndModal = (id, name) => {
    const { sessionId, media } = this.state;
    if (media.length === 0) {
      this.setState({
        endModal: true,
        sessionId: id,
        name,
      });
    } else {
      this.setState({
        endModal: id === sessionId || sessionId === "",
        messageModal: id !== sessionId && sessionId !== "",
        sessionId: sessionId ? sessionId : id,
        name,
      });
    }
  };
  hideEndModal = () => {
    const { media, sessionId } = this.state;
    this.setState({
      endModal: false,
      sessionId: media.length === 0 ? "" : sessionId,
    });
  };
  inputClick = (id) => {
    const { media, sessionId } = this.state;
    if (media.length === 0 || sessionId === "" || sessionId === id) {
      this.setState(
        {
          sessionId: id,
        },
        () => {
          this.imageInput.click();
        }
      );
    } else {
      this.setState({
        messageModal: true,
      });
    }
  };

  closeModal = () => {
    this.setState({
      messageModal: false,
    });
  };
  render() {
    const {
      media,
      viewMedia,
      fileToView,
      endModal,
      sessions,
      sessionId,
      messageModal,
      errorMessage,
      serverMessage,
      request,
      response,
      name,
      modalText,
    } = this.state;
    return (
      <React.Fragment>
        <div className="educator-sessions">
          {!response && (
            <div className="spinner-div">
              <img src={spinner} alt="Loading" width="70" />
            </div>
          )}
          {response && (
            <React.Fragment>
              <h5 className="title">{sessions.length} Active Sessions</h5>
              {sessions.map((session) => {
                const { _id, child, activity, start, end, center } = session;
                const { first_name, last_name, dob } = child;
                const { timezone } = center;
                let activity_name;
                let duration;
                if (activity) {
                  activity_name = activity.name;
                  duration = activity.duration;
                }
                const time = duration && duration.time;

                const startTime = helper.formatDateTime(
                  start,
                  "hh:mm",
                  timezone
                );
                const endTime = helper.formatDateTime(end, "hh:mm", timezone);
                const startPeriod = helper.formatDateTime(start, "a", timezone);
                const endPeriod = helper.formatDateTime(end, "a", timezone);
                const sessionDuration = (end - start) / 60 / 1000 / 60;
                let timeLeft = moment(end, "x").diff(moment(), "minutes");
                const minsLeftP = timeLeft < 0 ? "" : "MINS LEFT";
                timeLeft =
                  timeLeft < 0
                    ? `Ended on ${helper.formatDateTime(
                        end,
                        "YYYY-MMM-DD",
                        timezone
                      )}`
                    : timeLeft;

                const age = moment().diff(moment(dob, "x"), "years");
                const unit =
                  sessionDuration === 1 || time === 1 ? "Hour" : "Hours";

                const badge = helper.createBadge(first_name, last_name);
                return (
                  <div className="sessions-card" key={_id}>
                    <div className="top">
                      <div className="badge">{badge}</div>
                      <div className="name-age">
                        <h4 className="name">
                          {first_name} {last_name}
                        </h4>
                        <p className="age">{age} Years Old</p>
                      </div>
                      <div className="time-left">
                        <p>{timeLeft}</p>
                        <p>{minsLeftP}</p>
                      </div>
                    </div>
                    <div className="lesson-info">
                      <div className="lesson-name">
                        <p>
                          {activity
                            ? activity_name
                            : `${sessionDuration} ${unit} Booked`}
                        </p>
                        <p>
                          Duration • {duration ? time : sessionDuration} {unit}
                        </p>
                      </div>
                      <div className="lesson-time">
                        <div>
                          <p>SCHEDULED START TIME</p>
                          <p>
                            {startTime}&nbsp;<sup>{startPeriod}</sup>
                          </p>
                        </div>
                        <div>
                          <p>SCHEDULED END TIME</p>
                          <p>
                            {endTime}&nbsp;<sup>{endPeriod}</sup>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="session-buttons">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={(imageInput) => (this.imageInput = imageInput)}
                        onChange={this.handleMedia}
                        multiple
                      />
                      <button
                        className="green-button end"
                        onClick={() => this.showEndModal(_id, first_name)}
                      >
                        END SESSION
                      </button>
                      <button
                        className="green-button camera"
                        onClick={() => this.inputClick(_id)}
                      >
                        <img src={cameraIcon} alt="" />
                      </button>
                    </div>
                    {media.length > 0 && sessionId === _id && (
                      <div className="media-container">
                        {media.length > 0 &&
                          media.map((image, index) => {
                            const imageUrl = URL.createObjectURL(image);
                            let display;
                            if (image.type === "image/jpeg") {
                              display = (
                                <div className="media" key={index}>
                                  <div
                                    className="image-container"
                                    style={{
                                      backgroundImage: `url(${imageUrl})`,
                                    }}
                                    onClick={() => this.showMediaModal(image)}
                                  ></div>
                                  <img
                                    src={closeIcon}
                                    alt=""
                                    className="close-icon"
                                    onClick={() => this.removeMedia(image)}
                                  />
                                </div>
                              );
                            } else {
                              display = (
                                <div className="media" key={index}>
                                  <img
                                    src={videoIcon}
                                    alt=""
                                    className="video-icon"
                                    onClick={() => this.showMediaModal(image)}
                                  />
                                  <video
                                    className="video"
                                    src={`${imageUrl}`}
                                  ></video>
                                  <img
                                    src={closeIcon}
                                    alt=""
                                    className="close-icon"
                                    onClick={() => this.removeMedia(image)}
                                  />
                                </div>
                              );
                            }
                            return display;
                          })}
                      </div>
                    )}
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div>
        <Footer currentPage="active" />
        {viewMedia && (
          <Modal show={viewMedia} handleClose={this.hideMediaModal}>
            <ViewMedia file={fileToView} />
          </Modal>
        )}
        {
          <Modal show={endModal} handleClose={this.hideEndModal}>
            <EndSession
              handleComment={this.handleComment}
              endSession={this.endSession}
              id={sessionId}
              name={name}
              request={request}
              error={errorMessage}
              response={serverMessage}
              handleClose={this.hideEndModal}
            />
          </Modal>
        }
        <Modal show={messageModal} handleClose={this.closeModal}>
          <div className="end-session-modal">
            <h3 className="small-margin">
              Please end session for child with selected media before selecting
              media and/or ending session for this child.
            </h3>
            <div className="button-div">
              <button className="green-button" onClick={this.closeModal}>
                OK
              </button>
            </div>
          </div>
        </Modal>
        <SpinnerModal show={request} text={modalText} />
      </React.Fragment>
    );
  }
}
