import React, { Component } from 'react';
import helper from '../helper';
import headerLogo from '../../assets/svg/header-logo.svg';
import cart from '../../assets/svg/cart.svg';
import profileIcon from '../../assets/png/profile-img.png';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";

class Header extends Component {
  state = {
    cart: false,
  }

  componentDidMount() {
    this.getUserType();
    const token = localStorage.getItem('access_token');
    if(token){
      this.setAvatar();
    }
  }

  cart = () => {
    this.props.history.push({
      pathname: '/cart',
    })
  }

  educatorProfile = () => {
    this.props.history.push({
      pathname: '/educator/profile',
    })
  }

  getUserType = () => {
    this.props.dispatch({ 
      type: "FETCHUSERTYPE",
    });
  };

  
  setAvatar = async () => {
    const query = `{
      user{
        ...on EducatorType {
          avatar{
            url
          }
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    const token = await helper.verifyToken();
    const { user } = data;
    const { avatar } = user;
    let formattedUrl = '';
    if (avatar) {
      const { url } = avatar;
      formattedUrl = `${url}?access_token=${token}`;
    }
    this.props.dispatch({
      type: "SETAVATAR",
      payload: formattedUrl
    })
  }

  render() {
    const { count, userType, avatar } = this.props;
    return (
      <div>
        <div className="header">
          <img src={headerLogo} alt="" />
          {
            userType === 'parent' &&
            <React.Fragment>
              <img className='cart' onClick={this.cart} src={cart} alt="" />
              {
                (count > 0) &&
                <div className='cart-counter'>
                  {count}
                </div>
              }
            </React.Fragment>
          }
          {
            userType === 'educator' &&
            <div className="badge" onClick={this.educatorProfile}>
              <img src={avatar?avatar:profileIcon} alt="" />
            </div>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    count: state.count,
    userType: state.userType,
    avatar: state.avatar
  };
}

export default connect(mapStateToProps)(withRouter(Header));
