import React, { Component } from 'react';
import helper from '../helper';
import SpinnerModal from '../modal/SpinnerModal';
import plusIcon from '../../assets/svg/upload-icon.svg';
// import imageLoader from '../../assets/svg/spinner-bg.svg';
import { baseUrl } from '../url';

class AddContact extends Component {
  state = {
    first_name: this.props.first_name || '',
    last_name: this.props.last_name || '',
    relationship: this.props.relationship || '',
    phone_no: this.props.phone_no || '',
    country: 'nigeria',
    countryCode: '+234',
    makeResquest: false,
    action: this.props.action,
    imageUrl: this.props.imageUrl || '',
  }

  componentDidMount(){
    window.scrollTo(0, 0);
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: '',
    })
    if (e.target.name === 'country') {
      if (e.target.value === 'nigeria') {
        this.setState({
          countryCode: '+234'
        })
      }
      if (e.target.value === 'rwanda') {
        this.setState({
          countryCode: '+250'
        })
      }
    }
  }
  
  handleImage = (e) => {
    const file = e.target.files[0];
    this.setState({
      image: file,
      imageUrl: URL.createObjectURL(file),
    })
  }

  compressImage = async () => {
    const { image } = this.state;
    const result = await helper.compressImage(image, 0.5);
    if (result) {
      return result
    }
    return image
  }

  // uploadImage = async (image) => {
  //   this.setState({
  //     imageUrl: imageLoader
  //   })
  //   const data = new FormData()
  //   data.append('file', image)
  //   const token = await `Bearer ${await helper.verifyToken()}`;
  //     await fetch(`${baseUrl}/files`, {
  //         method: 'POST',
  //         headers: {
  //           'Accept': 'application/json',
  //           'Authorization': token
  //         },
  //         body: data
  //       })
  //       .then((response) => {
  //         this.setState({
  //           makeResquest: false,
  //         })
  //         if (helper.range(200, 299).indexOf(response.status) !== -1) {
  //           return response.json();
  //         }

  //         const e = new Error('Invalid status, status code: ' + response.status)
  //         e.status = response.status;
  //         e.response = response;
  //         throw e;
  //       })
  //       .then((responseJson) => {
  //         const { file } = responseJson;
  //         const { id } = file;
  //         this.setState({
  //           image: image,
  //           imageUrl: URL.createObjectURL(image),
  //           avatar: id
  //         })
  //       })
  //       .catch((error) => {
  //         console.error(error);
  //         console.log(error.response.json());
  //         if (error.status === 401) {
  //           // this.setState({
  //           //   codeError: 'The code you enter is invalid'
  //           // })
  //         }
  //       });
  // }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { first_name, last_name, phone_no, relationship, countryCode, image } = this.state;
    const errors = helper.validateContact(first_name, last_name, phone_no, relationship);
    if (errors) {
      const {
        first_nameError,
        last_nameError,
        relationshipError,
        phone_noError,
      } = errors;
      this.setState({
        first_nameError,
        last_nameError,
        relationshipError,
        phone_noError,
      })
    }
    if (!errors) {
      const { action } = this.state;
      const { id } = this.props;
      this.setState({
        makeResquest: true,
      })
      let uri;
      let methodType;
      const formattedPhone = action==='add'?`${countryCode}${phone_no.charAt(0)==='0'?phone_no.substring(1):phone_no}`:`${phone_no}`;
      if (action === 'add') {
        uri = `${baseUrl}/w/contacts`;
        methodType = 'POST';
      } else if (action === 'edit') {
        uri = `${baseUrl}/w/contacts/${id}`;
        methodType = 'PUT';
      }
      const token = await `Bearer ${await helper.verifyToken()}`;

      let data;
      let contentType;

      if (image) {
        contentType = 'multipart/form-data'
        const compressedImage = await this.compressImage();
        data = new FormData()
        data.append('first_name', first_name)
        data.append('last_name', last_name)
        data.append('relationship', relationship)
        data.append('phone_no', formattedPhone)
        data.append('avatar', compressedImage);
      } else {
        contentType = 'application/json'
        data = JSON.stringify({
          first_name,
          last_name,
          relationship,
          phone_no: formattedPhone,
        })
      }

      await fetch(uri, {
        method: methodType,
        headers: {
          'Accept': 'application/json',
          'Content-Type': contentType,
          'Authorization': token
        },
        body: data
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          })
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }

          const e = new Error('Invalid status, status code: ' + response.status)
          e.status = response.status;
          e.response = response;
          throw e;
        })
        .then((responseJson) => {
          console.log(responseJson);
          this.props.handleSubmit(action);
          if(action==='edit'){
            this.props.toggleOptions();
          }
        })
        .catch((error) => {
          console.error(error.response.json());
          if (error.status === 401) {
            // this.setState({
            //   codeError: 'The code you enter is invalid'
            // })
          }
        });
    }
  }

  render() {
    const { action } = this.props;
    const {
      first_name,
      last_name,
      phone_no,
      relationship,
      first_nameError,
      last_nameError,
      phone_noError,
      relationshipError,
      makeResquest,
      countryCode,
      imageUrl,
    } = this.state;
    const { cancel } = this.props;
    return (
      <React.Fragment>
        <div className="add-contact">
          <p className="text-title edit-text">
            {action==='add'&&'ADD CONTACT'}
            {action==='edit'&&'EDIT CONTACT'}
          </p>
          <form action="">
            {
              action==='edit'&&
              <label className="img-label">
                <input
                  type="file"
                  style={{display:'none'}}
                  ref={imageInput => (this.imageInput = imageInput)}
                  onChange={this.handleImage}/>
                <img
                  // src={plusIcon}
                  src={imageUrl?imageUrl:plusIcon}
                  alt=""
                  onClick={this.openFileDialog}/>
                {imageUrl?<p className="text" onClick={this.openFileDialog}>CHOOSE ANOTHER</p>:<p className="text">UPLOAD PHOTO</p>}
              </label>
            }
            <label>
              <p className="text">First name</p>
              <input type="text" name="first_name" value={first_name} onChange={this.handleChange} />
              <p className="error-text">{first_nameError}</p>
            </label>
            <label>
              <p className="text">Last name</p>
              <input type="text" name="last_name" value={last_name} onChange={this.handleChange} />
              <p className="error-text">{last_nameError}</p>
            </label>
            {
              action==='add' &&
              <label>
                <p className="text">Country</p>
                <select onChange={this.handleChange} name="country">
                  <option value='nigeria'>Nigeria</option>
                  {/* <option value='rwanda'>Rwanda</option> */}
                </select>
              </label>
            }
            {
              action==='add'&&
              <div className='label'>
                <p className="text">Phone Number</p>
                <input type="text" className='signin-input1' value={countryCode} disabled onChange={this.handleChange}/>
                <input className='signin-input2' type="text" value={phone_no} name="phone_no" onChange={this.handleChange}/>
                <p className="error-text">{phone_noError}</p>
              </div>
            }
            {
              action==='edit'&&
              <label>
                <p className="text">Phone Number</p>
                <input type="text" name="phone_no" value={phone_no} onChange={this.handleChange}/>
                <p className="error-text">{phone_noError}</p>
              </label>
            }
            <label>
              <p className="text">Relationship</p>
              <input type="text" name="relationship" value={relationship} onChange={this.handleChange} />
              <p className="error-text">{relationshipError}</p>
            </label>
            <div className="button-div">
              <button className="green-button" onClick={this.handleSubmit}>
                {action==='add'&&'ADD'}
                {action==='edit'&&'SAVE'}
              </button>
              <h5 onClick={cancel}>CANCEL</h5>
            </div>
          </form>
        </div>
        <SpinnerModal show={makeResquest} />
      </React.Fragment>
    )
  }
}

export default AddContact; 
