import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import { baseUrl } from "../../url";
import Footer from "../footer/Footer";
import helper from "../../helper";
import Modal from "../../modal/Modal";
import SpinnerModal from "../../modal/SpinnerModal";
import spinner from "../../../assets/svg/spinner.svg";
import checkIcon from "../../../assets/svg/drop-child.svg";

export default class NewSessions extends Component {
  state = {
    messageModal: false,
    errorMessage: "",
    serverMessage: "",
    sessions: [],
    response: false,
    confirmModal: false,
  };
  componentDidMount() {
    this.getSessions();
  }
  getSessions = async () => {
    const query = `{
      sessions(period: UPCOMING){
        _id,
        child{
          _id,
          first_name,
          last_name,
          dob,
        },
        activity{
          name,
          description,
          duration{
            time,
            unit
          },
          ageGroup{
            min
          },
        },
        center{
          name,
          address,
          timezone
        },
        dropTime,
        pickupTime,
        start,
        end,
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { sessions } = data;
    await this.setState({
      sessions,
      response: true,
    });
  };

  startSession = (id) => {
    this.setState({
      request: true,
      confirmModal: false,
    });
    const token = `Bearer ${localStorage.getItem("access_token")}`;
    fetch(`${baseUrl}/e/sessions/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        start: true,
      }),
    })
      .then((response) => {
        this.setState({
          request: false,
        });
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
        throw response;
      })
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson) {
          this.setState({
            serverMessage: responseJson.message,
            messageModal: true,
          });
        }
      })
      .catch((error) => {
        if (error) {
          error.json().then((e) => {
            console.log(e);
            this.setState({
              errorMessage: e.message,
              messageModal: true,
            });
          });
        }
      });
  };

  closeModal = () => {
    const { serverMessage } = this.state;
    this.setState(
      {
        messageModal: false,
        errorMessage: "",
      },
      () => {
        if (serverMessage) {
          this.getSessions();
          this.setState({
            serverMessage: "",
          });
        }
      }
    );
  };
  showConfirmModal = (id) => {
    this.setState({
      confirmModal: true,
      startId: id,
    });
  };
  hideConfirmModal = () => {
    this.setState({
      confirmModal: false,
      startId: null,
    });
  };
  render() {
    const {
      sessions,
      errorMessage,
      serverMessage,
      messageModal,
      response,
      request,
      confirmModal,
      startId,
    } = this.state;
    return (
      <React.Fragment>
        <div className="educator-sessions">
          {!response && (
            <div className="spinner-div">
              <img src={spinner} alt="Loading" width="70" />
            </div>
          )}
          {response && (
            <React.Fragment>
              <h5 className="title">{sessions.length} New Sessions</h5>
              {sessions.map((session) => {
                const { _id, child, activity, start, end, center } = session;
                const { timezone } = center;
                const { first_name, last_name, dob } = child;
                let activity_name;
                let duration;
                if (activity) {
                  activity_name = activity.name;
                  duration = activity.duration;
                }
                const time = duration && duration.time;

                const startTime = helper.formatDateTime(
                  start,
                  "hh:mm",
                  timezone
                );
                const endTime = helper.formatDateTime(end, "hh:mm", timezone);
                const startPeriod = helper.formatDateTime(start, "a", timezone);
                const endPeriod = helper.formatDateTime(end, "a", timezone);
                const sessionDuration = (end - start) / 60 / 1000 / 60;
                const day = moment(start, "x").format("DD");
                const monthYear = moment(start, "x").format("MMM, YYYY");
                // const timeLeft = moment(start, 'x').diff(moment());
                // const humanizedTime = moment.duration(timeLeft).humanize();
                const age = moment().diff(moment(dob, "x"), "years");
                const unit =
                  sessionDuration === 1 || time === 1 ? "Hour" : "Hours";

                const badge = helper.createBadge(first_name, last_name);
                return (
                  <div className="sessions-card" key={_id}>
                    <div className="top">
                      <div className="badge">{badge}</div>
                      <div className="name-age">
                        <h4 className="name">
                          {first_name} {last_name}
                        </h4>
                        <p className="age">{age} Years Old</p>
                      </div>
                      <div className="time-left">
                        <p>{day}</p>
                        <p>{monthYear}</p>
                      </div>
                    </div>
                    <div className="lesson-info">
                      <div className="lesson-name">
                        <p>
                          {activity
                            ? activity_name
                            : `${sessionDuration} ${unit} Booked`}
                        </p>
                        <p>
                          Duration • {duration ? time : sessionDuration} {unit}
                        </p>
                      </div>
                      <div className="lesson-time">
                        <div>
                          <p>SCHEDULED START TIME</p>
                          <p>
                            {startTime}&nbsp;<sup>{startPeriod}</sup>
                          </p>
                        </div>
                        <div>
                          <p>SCHEDULED END TIME</p>
                          <p>
                            {endTime}&nbsp;<sup>{endPeriod}</sup>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="session-buttons new">
                      <button
                        className="green-button start"
                        onClick={() => this.showConfirmModal(_id)}
                      >
                        START SESSION
                      </button>
                    </div>
                  </div>
                );
              })}
            </React.Fragment>
          )}
        </div>
        <Footer currentPage="new" />
        <Modal show={messageModal} handleClose={this.closeModal}>
          <div className="popup end-session-modal">
            {serverMessage && <img src={checkIcon} alt="" />}
            {errorMessage && <h3 className="small-margin">{errorMessage}</h3>}
            {serverMessage && <h3 className="small-margin">{serverMessage}</h3>}
            <div className="button-div">
              <button className="green-button" onClick={this.closeModal}>
                OK
              </button>
            </div>
          </div>
        </Modal>
        <Modal show={confirmModal} handleClose={this.hideConfirmModal}>
          <div className="end-session-modal">
            <h3 className="small-margin">Do you want to start this Session?</h3>
            <div className="button-div">
              <button className="hollow-button" onClick={this.hideConfirmModal}>
                No
              </button>
              <button
                className="green-button"
                onClick={() => this.startSession(startId)}
              >
                Yes, start Session
              </button>
            </div>
          </div>
        </Modal>
        <SpinnerModal show={request} />
      </React.Fragment>
    );
  }
}
