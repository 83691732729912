import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from "react-router-dom";
import Header from "./components/header/header";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import SignUpCode from "./components/SignUpCode";
import SignInCode from "./components/SignInCode";
import MySessions from "./components/mySessions/mySessions";
import BookSession from "./components/bookSessions/BookSession";
import Profile from "./components/profile/profile";
import Centers from "./components/centers/Centers";
import helper from "./components/helper";
import Cart from "./components/cart/Cart";
import Membership from "./components/membership/MemberShip";
import SeeMore from "./components/mySessions/SeeMore";
import EducatorSessionsActive from "./components/Educator/sessions/ActiveSessions";
import EducatorSessionsNew from "./components/Educator/sessions/NewSessions";
import EducatorProfile from "./components/Educator/profile/EducatorProfile";
import EditEducatorProfile from "./components/Educator/profile/EditEducatorProfile";
import { ProtectedRoute } from "./components/Authentication";
import "./App.css";
import CacheBuster from "./CacheBuster";

function App() {
  // localStorage.clear();
  const userType = localStorage.getItem("user_type");
  helper.verifyToken();

  return (
    <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload, version }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
          localStorage.setItem("appVersion", version);
        }
        return (
          <div className="App">
            <Router>
              <Header />
              <Switch>
                <Route
                  exact
                  path="/"
                  render={() => (
                    <Redirect
                      to={
                        userType === "educator"
                          ? "/educator/sessions/new"
                          : "/sessions/booked-sessions"
                      }
                    />
                  )}
                />
                <Route path="/signin" component={SignIn} />
                <Route path="/signup" component={SignUp} />
                <Route path="/signin-verification" component={SignInCode} />
                <Route path="/signup-verification" component={SignUpCode} />
                <Route
                  exact
                  path="/sessions"
                  render={() => <Redirect to="/sessions/booked-sessions" />}
                />
                <ProtectedRoute
                  exact
                  path="/sessions/:sessions_type"
                  component={MySessions}
                />
                <ProtectedRoute
                  exact
                  path="/sessions/:sessions_type/:id"
                  component={MySessions}
                />
                <ProtectedRoute path="/book-session" component={BookSession} />
                <ProtectedRoute path="/profile" component={Profile} />
                <ProtectedRoute path="/centers" component={Centers} />
                <ProtectedRoute path="/cart" component={Cart} />
                <ProtectedRoute path="/membership" component={Membership} />
                <ProtectedRoute path="/see-more/:id" component={SeeMore} />
                <ProtectedRoute
                  path="/educator/sessions/active"
                  component={EducatorSessionsActive}
                />
                <ProtectedRoute
                  path="/educator/sessions/new"
                  component={EducatorSessionsNew}
                />
                <ProtectedRoute
                  exact
                  path="/educator/profile"
                  component={EducatorProfile}
                />
                <ProtectedRoute
                  path="/educator/profile/edit"
                  component={EditEducatorProfile}
                />
              </Switch>
            </Router>
          </div>
        );
      }}
    </CacheBuster>
  );
}

export default App;
