import React, { Component } from 'react';
import down from '../../assets/svg/orange-down.svg';
import up from '../../assets/svg/up.svg';
import { connect } from "react-redux";
import DatePicker from 'react-datepicker';
import moment from "moment";
import 'moment-timezone';

import "react-datepicker/dist/react-datepicker.css";


  // addToCart = () => {
  //   this.props.dispatch({ type: "INCREMENT" });
  // };

class Duration extends Component {
  state = {
    open: '',
    close: '',
  }
  componentWillReceiveProps(nextProps){
    const { open, close } = nextProps.openingHours[0];
    this.setState({
      open,
      close 
    })
  }

  handleDateChangeRaw = (e) => {
    e.preventDefault();
  }

  render() {
    const { duration, expand, date, handleDate, time, handleTime, book } = this.props;
    const { open, close } = this.state;
    const openFormatted = moment(open, 'ha');
    const closeFormatted = moment(close, 'ha');
    return (
      <React.Fragment>
        <div className='hour-div'>
          <div className='title' name='one'>
            <p className='text'>1 Hour</p>
            {
              duration === 1 ?
                <button className="button-close" onClick={() => expand(0)}>CLOSE<img className='down' src={up} alt="" /></button> :
                <button className="orange-button" onClick={() => expand(1)}>SELECT<img className='down' src={down} alt="" /></button>
            }
          </div>
          {
            duration === 1 &&
            <div className={`expanded ${duration === 1?'show':''}`}>
              <p className='text'>Select date and time</p>
              <div className='selector'>
                <DatePicker
                  selected={date}
                  minDate={new Date()}
                  onChange={handleDate}
                  placeholderText="Select Date"
                  className="input-style"
                  onFocus={(e) => e.target.readOnly = true}
                  onChangeRaw={(e) => e.preventDefault()} 
                />
                <DatePicker
                  selected={time}
                  onChange={handleTime}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={new Date(openFormatted)}
                  maxTime={new Date(new Date((closeFormatted)*1 - 1000*3600*1))}
                  timeIntervals={60}
                  dateFormat="h:mm aa"
                  timeCaption="Time"
                  placeholderText="Select Time"
                  className="input-style"
                  onFocus={(e) => e.target.readOnly = true}
                  onChangeRaw={(e) => e.preventDefault()} 
                />
              </div>
              <div className='addButton'>
                <button 
                  className="green-button"
                  disabled={(!date || !time)}
                  onClick={book}>
                    ADD TO CART
                </button>
              </div>
            </div>
          }
        </div>
        <div className='hour-div' name='two'>
          <div className='title'>
            <p className='text'>2 Hours</p>
            {
              duration === 2 ?
                <button className="button-close" onClick={() => expand(0)}>CLOSE<img className='down' src={up} alt="" /></button> :
                <button className="orange-button" onClick={() => expand(2)}>SELECT<img className='down' src={down} alt="" /></button>
            }
          </div>
          {
            duration === 2 &&
            <div className='expanded'>
              <p className='text'>Select date and time</p>
              <div className='selector'>
                <DatePicker
                  selected={date}
                  onChange={handleDate}
                  placeholderText="Select Date"
                  className="input-style"
                  onFocus={(e) => e.target.readOnly = true}
                  onChangeRaw={(e) => e.preventDefault()} 
                />
                <DatePicker
                  selected={time}
                  onChange={handleTime}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={new Date(openFormatted)}
                  maxTime={new Date((closeFormatted)*1 - 1000*3600*2)}
                  timeIntervals={60}
                  dateFormat="h:mm aa"
                  timeCaption="Time"
                  placeholderText="Select Time"
                  className="input-style"
                  onFocus={(e) => e.target.readOnly = true}
                  onChangeRaw={(e) => e.preventDefault()} 
                />
              </div>
              <div className='addButton'>
                <button 
                  className="green-button"
                  disabled={(!date || !time)}
                  onClick={book}>
                    ADD TO CART
                </button>
              </div>
            </div>
          }
        </div>
        <div className='hour-div' name='three'>
          <div className='title'>
            <p className='text'>3 Hours</p>
            {
              duration === 3 ?
                <button className="button-close" onClick={() => expand(0)}>CLOSE<img className='down' src={up} alt="" /></button> :
                <button className="orange-button" onClick={() => expand(3)}>SELECT<img className='down' src={down} alt="" /></button>
            }
          </div>
          {
            duration === 3 &&
            <div className='expanded'>
              <p className='text'>Select date and time</p>
              <div className='selector'>
                <DatePicker
                  selected={date}
                  onChange={handleDate}
                  placeholderText="Select Date"
                  className="input-style"
                  onFocus={(e) => e.target.readOnly = true}
                  onChangeRaw={(e) => e.preventDefault()} 
                />
                <DatePicker
                  selected={time}
                  onChange={handleTime}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={new Date(openFormatted)}
                  maxTime={new Date(new Date((closeFormatted)*1 - 1000*3600*3))}
                  timeIntervals={60}
                  dateFormat="h:mm aa"
                  timeCaption="Time"
                  placeholderText="Select Time"
                  className="input-style"
                  onFocus={(e) => e.target.readOnly = true}
                  onChangeRaw={(e) => e.preventDefault()} 
                />
              </div>
              <div className='addButton'>
                <button 
                  className="green-button"
                  disabled={(!date || !time)}
                  onClick={book}>
                    ADD TO CART
                </button>
              </div>
            </div>
          }
        </div>
        <div className='hour-div' name='four'>
          <div className='title'>
            <p className='text'>4 Hours</p>
            {
              duration === 4 ?
                <button className="button-close" onClick={() => expand(0)}>CLOSE<img className='down' src={up} alt="" /></button> :
                <button className="orange-button" onClick={() => expand(4)}>SELECT<img className='down' src={down} alt="" /></button>
            }
          </div>
          {
            duration === 4 &&
            <div className='expanded'>
              <p className='text'>Select date and time</p>
              <div className='selector'>
                <DatePicker
                  selected={date}
                  onChange={handleDate}
                  placeholderText="Select Date"
                  className="input-style"
                  onFocus={(e) => e.target.readOnly = true}
                  onChangeRaw={(e) => e.preventDefault()} 
                />
                <DatePicker
                  selected={time}
                  onChange={handleTime}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={new Date(openFormatted)}
                  maxTime={new Date(new Date((closeFormatted)*1 - 1000*3600*4))}
                  timeIntervals={60}
                  dateFormat="h:mm aa"
                  timeCaption="Time"
                  placeholderText="Select Time"
                  className="input-style"
                  onFocus={(e) => e.target.readOnly = true}
                  onChangeRaw={(e) => e.preventDefault()} 
                />
              </div>
              <div className='addButton'>
                <button 
                  className="green-button"
                  disabled={(!date || !time)}
                  onClick={book}>
                    ADD TO CART
                </button>
              </div>
            </div>
          }
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps(state) {
  return {
    count: state.count
  };
}

export default connect(mapStateToProps)(Duration);
