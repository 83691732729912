import React, { Component } from "react";
import moment from "moment";
import "moment-timezone";
import Footer from "../footer/Footer";
import left from "../../../assets/svg/left.svg";
import helper from "../../helper";
import spinner from "../../../assets/svg/spinner.svg";
import Modal from "../../modal/Modal";
import { baseUrl } from "../../url";

export default class EducatorProfile extends Component {
  toType = (obj) => {
    return {}.toString
      .call(obj)
      .match(/\s([a-zA-Z]+)/)[1]
      .toLowerCase();
  };
  state = {
    user: {},
    previousSessions: [],
    response: false,
    show: false,
    editReport: false,
    session: undefined,
    comment: "",
    error: [],
  };
  componentDidMount() {
    this.fetchProfile();
    this.fetchSessionsHistory();
  }
  goBack = () => {
    this.props.history.goBack();
  };
  showEditProfile = () => {
    this.props.history.push({
      pathname: "/educator/profile/edit",
    });
  };
  fetchProfile = async () => {
    const query = `{
      user{
        _id,
        last_name,
        first_name,
        ...on EducatorType {
          avatar{
            url
          }
        }
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { user } = data;
    await this.setState({
      user,
    });
  };
  fetchSessionsHistory = async () => {
    const query = `{
      sessions(status: COMPLETED){
        _id
        child{
          first_name,
          last_name,
          dob,
        },
        activity{
          name,
          duration{
            time,
            unit
          }
        },
        center{
          timezone,
        },
        dropTime,
        pickupTime,
        start,
        end,
        comment,
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { sessions } = data;
    await this.setState({
      previousSessions: sessions,
      response: true,
    });
  };

  showLogOutModal = () => {
    this.setState({
      show: true,
    });
  };

  hideLogOutModal = () => {
    this.setState({ show: false });
  };

  hideEditModal = () => {
    this.setState({ editReport: false, session: undefined });
  };

  logOut = () => {
    localStorage.clear();
    this.props.history.push({
      pathname: "/signin",
    });
  };

  handleReportEdit = (session) => {
    console.log(session);
    this.setState({ editReport: true, session });
  };

  handleComment = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  editReportRequest = (_id) => {
    const token = `Bearer ${localStorage.getItem("access_token")}`;
    const { comment } = this.state;
    console.log(comment);
    fetch(`${baseUrl}/e/sessions/${_id}/report`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: token,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        comment,
      }),
    })
      .then(() => {
        this.setState({ editReport: false, session: undefined });
        window.location.reload();
      })
      .catch((e) => this.setState({ error: [e.message] }));
  };

  render() {
    const {
      user,
      previousSessions,
      response,
      show,
      editReport,
      session,
      error,
    } = this.state;
    const { last_name, first_name, avatar } = user;
    let url = avatar ? avatar.url : "";
    let token = localStorage.getItem("access_token");
    const badge = helper.createBadge(first_name, last_name);
    return (
      <React.Fragment>
        <div className="back-panel">
          <span onClick={this.goBack}>
            <img className="left" src={left} alt="" />
            <p>My Profile</p>
          </span>
          <p className="logout" onClick={this.showLogOutModal}>
            LOGOUT
          </p>
        </div>
        <div className="educator-profile">
          {!response && (
            <div className="spinner-div">
              <img src={spinner} alt="Loading" width="70" />
            </div>
          )}
          {response && (
            <React.Fragment>
              <div className="top">
                <div className="name-badge">
                  {url ? (
                    <div
                      className="img"
                      style={{
                        backgroundImage: `url(${url}?access_token=${token})`,
                      }}
                    ></div>
                  ) : (
                    <div className="badge">{badge}</div>
                  )}
                  <div>
                    <p>
                      {first_name} {last_name}
                    </p>
                    <p>{previousSessions.length} Sessions taken</p>
                  </div>
                </div>
                <button
                  className="orange-button"
                  onClick={this.showEditProfile}
                >
                  EDIT PROFILE
                </button>
              </div>
              <div className="profile-tab">Session History</div>
              <div className="educator-sessions">
                {previousSessions.map((session) => {
                  const { _id, child, activity, start, end, center } = session;
                  const { timezone } = center;
                  const { first_name, last_name, dob } = child;

                  let activity_name;
                  let duration;
                  if (activity) {
                    activity_name = activity.name;
                    duration = activity.duration;
                  }
                  const time = duration && duration.time;

                  const date = helper.formatDateTime(
                    start,
                    "DD/MM/YYYY",
                    timezone
                  );
                  const startTime = helper.formatDateTime(
                    start,
                    "hh:mm",
                    timezone
                  );
                  const endTime = helper.formatDateTime(end, "hh:mm", timezone);
                  const startPeriod = helper.formatDateTime(
                    start,
                    "a",
                    timezone
                  );
                  const endPeriod = helper.formatDateTime(end, "a", timezone);
                  const sessionDuration = (end - start) / 60 / 1000 / 60;
                  const age = moment().diff(moment(dob, "x"), "years");
                  const unit =
                    sessionDuration === 1 || time === 1 ? "Hour" : "Hours";

                  const badge = helper.createBadge(first_name, last_name);
                  return (
                    <div className="sessions-card" key={_id}>
                      <div className="top">
                        <div className="badge">{badge}</div>
                        <div className="name-age">
                          <h4 className="name">
                            {first_name} {last_name}
                          </h4>
                          <p className="age">{age} Years Old</p>
                        </div>
                        <button
                          className="green-button-report"
                          onClick={() => this.handleReportEdit(session)}
                        >
                          Edit Report
                        </button>
                      </div>
                      <div className="lesson-info">
                        <div className="lesson-name">
                          <p>
                            {activity
                              ? activity_name
                              : `${sessionDuration} ${unit} Booked`}
                          </p>
                          <p>
                            Duration • {duration ? time : sessionDuration}{" "}
                            {unit}
                          </p>
                        </div>
                        <div className="lesson-time">
                          <div>
                            <p>DATE</p>
                            <p>{date}</p>
                          </div>
                          <div>
                            <p>START TIME</p>
                            <p>
                              {startTime}&nbsp;<sup>{startPeriod}</sup>
                            </p>
                          </div>
                          <div>
                            <p>END TIME</p>
                            <p>
                              {endTime}&nbsp;<sup>{endPeriod}</sup>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </React.Fragment>
          )}
          <Modal show={show} handleClose={this.hideLogOutModal}>
            <div className="popup">
              <p className="text">Are you sure you want to log out?</p>
              <div className={"modalBtn"}>
                <button
                  className="green-button outline"
                  onClick={this.hideLogOutModal}
                >
                  NO
                </button>
                <button className="green-button" onClick={this.logOut}>
                  YES
                </button>
              </div>
            </div>
          </Modal>
          {editReport && session && (
            <Modal show={editReport} handleClose={this.hideEditModal}>
              <div className="end-session-modal">
                <React.Fragment>
                  <div className="popup">
                    <h3>Edit Report </h3>
                    <textarea
                      onChange={this.handleComment}
                      defaultValue={session.comment}
                    ></textarea>
                    {error && this.toType(error) === this.toType([]) ? (
                      error.map((item, index) => {
                        const { message } = item;
                        return (
                          <p className="error-text" key={index}>
                            {message}
                          </p>
                        );
                      })
                    ) : (
                      <p className="error-text">{error && error.message}</p>
                    )}
                    <div className="button-div">
                      <button
                        className="green-button"
                        onClick={() => this.editReportRequest(session._id)}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </Modal>
          )}
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
