import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import navNew from '../../../assets/svg/nav-sessions.svg';
import navActive from '../../../assets/svg/educator-nav-active.svg';
import navNewActive from '../../../assets/svg/educator-new.svg';
import navActiveActive from '../../../assets/svg/educator-active.svg';

class Footer extends Component {
  render() {
    const { currentPage } = this.props;
    return (
      <div className="footer educator">
        <Link to="/educator/sessions/new" style={{ textDecoration: 'none' }} className={currentPage === "new"?'footer-nav-item active':'footer-nav-item'}>
          <img src={currentPage === "new"? navNewActive:navNew} alt="" />
          <p>New Sessions</p>
        </Link>
        <Link to="/educator/sessions/active" style={{ textDecoration: 'none' }} className={currentPage === "active"?'footer-nav-item active':'footer-nav-item'}>
          <img src={currentPage === "active"? navActiveActive:navActive} alt="" />
          <p>Active Sessions</p>
        </Link>
      </div>
    )
  }
}

export default Footer;
