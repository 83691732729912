import React, { Component } from 'react';
import moment from "moment";
import 'moment-timezone';
import { connect } from "react-redux";
import Footer from '../footer/footer';
import Top from '../top/Top';
import TopCard from './TopCard';
import Activity from './Activity';
import Duration from './Duration';
import bookDuration from '../../assets/svg/bookduration.svg';
import bookActivity from '../../assets/svg/bookactivity.svg';
import right from '../../assets/svg/right.svg';
import left from '../../assets/svg/left.svg';
import dropChild from '../../assets/svg/drop-child.svg';
import helper from '../helper';
import Modal from '../modal/Modal';
import SpinnerModal from '../modal/SpinnerModal';
import spinner from '../../assets/svg/spinner.svg';
import { baseUrl } from '../url';

class BookSession extends Component {
  state = {
    show: false,
    child: false,
    showErrorModal: false,
    weekendExplorerModal: false,
    children: [],
    centers: [],
    selectedChild: '',
    selectedCenter: '',
    page: '',
    disabled: true,
    duration: 0,
    date: '',
    time: '',
    makeResquest: false,
    response: false,
    openingHours: [{
      day: "0",
      open: "1pm",
      close: "1pm"
    }]
  }

  componentDidMount(){
    this.listCenters();
    this.listChildren();
  }

  listCenters = async () => {
    const query = `{
      centers(includeActivities:true){
        name
        _id
        opsHrs{
          day
          open
          close
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    const { centers } = data;
    await this.setState({
      centers
    })
  }

  listChildren = async () => {
    const parent_id = localStorage.getItem('parent_id');
    const query = `{
          children(parent_id:"${parent_id}"){
            first_name,
            last_name,
            _id,
          }
        }`
    const data = await helper.apolloQuery(query);
    const { children } = data;
    await this.setState({
      children,
      response: true,
    })
  }

  addToCart = () => {
    this.props.dispatch({ type: "INCREMENT" });
    this.setState({
      date: '',
      time: '',
    })
  };

  getCount = () => {
    this.props.dispatch({ type: "GETCOUNT" });
  };

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false }, () => {
      this.handlePage('');
    });
  };

  showErrorModal = () => {
    this.setState({
      showErrorModal: true
    })
  }

  hideErrorModal = () => {
    this.setState({
      showErrorModal: false,
      apiError: ''
    })
  }

  handleChild = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const child_id = e.target.options[selectedIndex].getAttribute('data-key');
    this.setState({
      selectedChild: e.target.value,
      child_id
    })
  }

  handleCenter = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const center_id = e.target.options[selectedIndex].getAttribute('data-key');
    this.setState({
      selectedCenter: e.target.value,
      center_id,
    }, () => {
      this.getCenterDetails(center_id);
    })
  }

  getCenterDetails = (id) => {
    const { centers } = this.state;
    const centerDetails = centers.filter(center => center._id === id);
    this.setState({
      centerDetails
    })
  }

  handleDate = (date) => {
    this.setState({
      date,
    }, () => {
      const datePicked = moment(date).format('e');
      this.handleOpeningHours(datePicked);
    })
  }

  handleOpeningHours = (day) => {
    const { centers } = this.state;
    const details = centers[0];
    const { opsHrs } = details;
    const openingHours = opsHrs.filter(hour => hour.day === Number(day));
    this.setState({
      openingHours,
    })
  }

  handleTime = (time) => {
    this.setState({
      time,
    })
  }

  enable = () => {
    this.setState({
      disabled: false,
    })
  }

  handlePage = (page) => {
    this.setState({
      page
    })
  }

  expand = (duration) => {
    this.setState({
      duration
    })
  }

  pickActivity = (activity, duration) => {
    this.setState({
      activity_id: activity,
      duration,
    })
  }

  bookSession = async () => {
    this.setState({
      makeResquest: true,
    })
    const { child_id, activity_id, center_id, date, time, duration } = this.state;
    console.log(time);
    const formattedDate = moment(date).format('YYYY-MM-DD');
    const formattedTime = moment(time).format('HH:mm');

    const token = await `Bearer ${await helper.verifyToken()}`;
    await fetch(`${baseUrl}/w/sessions/cart/add`, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token
      },
      body: JSON.stringify({
        child: child_id,
        center: center_id,
        activity: activity_id,
        date: formattedDate,
        time: formattedTime,
        duration: {
          time: duration,
          unit: 'h'
        }
      })
    })
      .then(async (response) => {
        this.setState({
          makeResquest: false,
        })
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
        if(response.status === 400){
          const responseJson = await response.json();
          this.setState({
            apiError: responseJson.message,
          },() => {
            this.showErrorModal();
          })
        }
        const e = new Error('Invalid status, status code: ' + response.status)
        e.status = response.status;
        e.response = response;
        throw e;
      })
      .then((responseJson) => {
        this.showModal();
        this.addToCart();
      })
      .catch((error) => {
        console.error(error);
        // const errorJson = error.response.json();
        // if(error.status === 400){
        //   this.setState({
        //     backendError: errorJson.message
        //   })
        // }
        if (error.status === 401) {
          // this.setState({
          //   codeError: 'The code you enter is invalid'
          // })
        }
      });
  }

  checkChildren(children) {
    return children && children.length?true:false;
  }

  render() {
    const {
      selectedChild,
      selectedCenter,
      page,
      disabled,
      duration,
      date,
      time,
      show,
      activity_id,
      makeResquest,
      showErrorModal,
      centers,
      openingHours,
      children,
      response,
      apiError,
    } = this.state;
    const hasChildren = this.checkChildren(children);
    return (
      <div className='book-session'>
        {
          !response &&
          <div className="spinner-div">
            <img src={spinner} alt='Loading' width='70' />
          </div>
        }
        {
          response &&
          <React.Fragment>
            {
              page === '' &&
              <Top title='Book Session' />
            }
            {
              page !== '' &&
              <div className='top-back-panel'>
                <div onClick={() => this.handlePage('')}>
                  <img className='left' src={left} alt="" />
                  <p>Back to Book Session</p>
                </div>
              </div>
            }
            <div className='book-session-body'>
              <TopCard
                handleChild={this.handleChild}
                handleCenter={this.handleCenter}
                enable={this.enable}
                selectedChild={selectedChild}
                selectedCenter={selectedCenter}
                centers={centers}
                children={children} />
              {
                (page !== 'activity' && page !== 'duration') &&
                <div className='list-div' disabled={!hasChildren}>
                  <div className='list-item' onClick={disabled ? () => this.showErrorModal() : () => this.handlePage('duration')}>
                    <div className='title'>
                      <img src={bookDuration} alt="" />
                      <p className='text'>Book by Duration</p>
                    </div>
                    <div>
                      <img className='right' src={right} alt="" />
                    </div>
                  </div>
                  <div className='list-item' onClick={disabled ? () => this.showErrorModal() : () => this.handlePage('activity')}>
                    <div className='title'>
                      <img src={bookActivity} alt="" />
                      <p className='text'>Book by Activity</p>
                    </div>
                    <div>
                      <img className='right' src={right} alt="" />
                    </div>
                  </div>
                </div>
              }
              {
                page === 'activity' &&
                <Activity
                  duration={duration}
                  activity_id={activity_id}
                  pickActivity={this.pickActivity}
                  date={date}
                  handleDate={this.handleDate}
                  time={time}
                  handleTime={this.handleTime}
                  book={this.bookSession}
                  openingHours={openingHours} />
              }
              {
                page === 'duration' &&
                <Duration
                  duration={duration}
                  expand={this.expand}
                  date={date}
                  handleDate={this.handleDate}
                  time={time}
                  handleTime={this.handleTime}
                  book={this.bookSession}
                  openingHours={openingHours} />
              }
            </div>
          </React.Fragment>
        }
        <Footer currentPage="session" />
        <Modal show={show} handleClose={this.hideModal}>
          <div className="popup">
            <img src={dropChild} alt="" />
            <p className="text">Session added to cart successfully.<br />It would be kept for 30 minutes.</p>
          </div>
        </Modal>
        <Modal show={showErrorModal} handleClose={this.hideErrorModal}>
          <div className="errorPopup">
            {
              disabled && <p className="text">Select child info before booking</p>
            }
            {
              apiError && <p className="text">{apiError}</p>
            }
            <button className="green-button" onClick={this.hideErrorModal}>Got it</button>
          </div>
        </Modal>
        <SpinnerModal show={makeResquest} />
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    count: state.count
  };
}

// export default withRouter(BookSession);
export default connect(mapStateToProps)(BookSession);
