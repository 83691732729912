import React, { Component } from "react";
import Top from "../top/Top";
import Footer from "../footer/footer";
import lekkiCenterImage from "../../assets/png/center-image.png";
import yabaCenterImage from "../../assets/png/yaba-space.png";
import nairobiCenterImage from "../../assets/png/sc-nairobi.png";
import kigaliCenterImage from "../../assets/png/kigali-space.png";
import portHarcourtImage from "../../assets/png/port-harcout.jpg";

export default class Centers extends Component {
  render() {
    return (
      <React.Fragment>
        <Top title="Find a Centre" />
        <div className="centers">
          <div className="center-card">
            <img src={lekkiCenterImage} alt="" />
            <div className="info">
              <p className="text-title">Lekki</p>
              <p className="text">
                1st Floor, 10Bou Plaza, Beside Global International School.
                Admiralty way, Lekki, Lagos, Nigeria.
              </p>
              <div>
                <a href="https://www.google.com/maps/dir/?api=1&destination=STEM+Cafe,+Lekki+Phase+I,+Lagos">
                  <button className="orange-button">GET DIRECTIONS</button>
                </a>
                <a href="tel:+23408094003967">
                  <button className="orange-button orange">Call Us</button>
                </a>
              </div>
            </div>
          </div>
          <div className="center-card">
            <img src={yabaCenterImage} alt="" />
            <div className="info">
              <p className="text-title">Yaba</p>
              <p className="text">3rd Floor, 8 Montgomery Road, Sabo Yaba</p>
              <div>
                <a href="https://www.google.com/maps/dir/?api=1&destination=3rd%20Floor,%208%20Montgomery%20Road,%20Sabo%20Yaba">
                  <button className="orange-button">GET DIRECTIONS</button>
                </a>
                <a href="tel:+23408094003967">
                  <button className="orange-button orange">Call Us</button>
                </a>
              </div>
            </div>
          </div>
          {/* <div className="center-card">
            <img src={lekkiCenterImage} alt="" />
            <div className="info">
              <p className="text-title">Kampala</p>
              <p className="text">
                Ntinda Complex, Kampala, Block D, Ground Floor
              </p>
              <div>
                <a href="https://www.google.com/maps/dir/?api=1&destination=Ntinda+Complex%2C+Kampala">
                  <button className="orange-button">GET DIRECTIONS</button>
                </a>
                <a href="tel:+256789631395">
                  <button className="orange-button orange">Call Us</button>
                </a>
              </div>
            </div>
          </div> */}
          <div className="center-card">
            <img src={nairobiCenterImage} alt="" />
            <div className="info">
              <p className="text-title">Nairobi</p>
              <p className="text">154 James Gichuru Road, Jahazi, Nairobi</p>
              <div>
                <a href="https://www.google.com/maps/dir/?api=1&destination=154%20James%20Gichuru%20Road,%20Jahazi,%20Nairobi">
                  <button className="orange-button">GET DIRECTIONS</button>
                </a>
                <a href="tel:+254110000202">
                  <button className="orange-button orange">Call Us</button>
                </a>
              </div>
            </div>
          </div>
          <div className="center-card">
            <img src={kigaliCenterImage} alt="" />
            <div className="info">
              <p className="text-title">Kigali</p>
              <p className="text">
                DesignLab, Golden Plaza, 1st Floor, KG546 St.1 Kacyiru Gasabo
              </p>
              <div>
                <a href="https://www.google.com/maps/dir/?api=1&destination=DesignLab%2C+Golden+Plaza%2C+1st+Floor%2C+KG546+St.1+Kacyiru+Gasabo">
                  <button className="orange-button">GET DIRECTIONS</button>
                </a>
                <a href="tel:+250790137616">
                  <button className="orange-button orange">Call Us</button>
                </a>
              </div>
            </div>
          </div>
          <div className="center-card">
            <img src={portHarcourtImage} alt="" />
            <div className="info">
              <p className="text-title">Port Harcourt</p>
              <p className="text">26 Isiokpo Street, D-Line, Port Harcourt</p>
              <div>
                <a href="https://www.google.com/maps/dir/?api=1&destination=26%20Isiokpo%20Street,%20D-Line,%20Port%20Harcourt%22">
                  <button className="orange-button">GET DIRECTIONS</button>
                </a>
                <a href="tel:+2347072975490">
                  <button className="orange-button orange">Call Us</button>
                </a>
              </div>
            </div>
          </div>
        </div>
        <Footer currentPage="centers" />
      </React.Fragment>
    );
  }
}
