import React, { Component } from 'react';
import helper from '../helper';
import SpinnerModal from '../modal/SpinnerModal';
import Modal from '../modal/Modal';
import dropChild from '../../assets/svg/drop-child.svg';
import plusIcon from '../../assets/svg/upload-icon.svg';
import { baseUrl } from '../url';

class EditProfile extends Component {
  state = {
    first_name: '',
    last_name: '',
    phone_no: '',
    email: '',
    imageUrl: '',
    makeResquest: false,
    avatar: '',
    show: false,
  }

  componentDidMount(){
    window.scrollTo(0, 0);
    this.fetchProfile();
  }
  
  showModal = () => {
    this.setState({ show: true });
  };
  hideModal = () => {
    this.setState({ show: false });
    this.props.closeForm();
  };

  fetchProfile = async () => {
    const query = `{
      user{
        _id,
        last_name,
        first_name,
        email,
        phone_no,
        ...on Parent {
          avatar{
            url
          }
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    const token = await helper.verifyToken();
    const { first_name, last_name, email, phone_no, avatar } = data.user
    let formattedUrl = '';
    if (avatar) {
      const { url } = avatar;
      formattedUrl = `${url}?access_token=${token}`;
    }
    await this.setState({
      first_name,
      last_name,
      email,
      phone_no,
      imageUrl: formattedUrl,
    })
  }

  handleImage = (e) => {
    const file = e.target.files[0];
    this.setState({
      image: file,
      imageUrl: URL.createObjectURL(file),
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + 'Error']: '',
    })
  }

  compressImage = async () => {
    const {image} = this.state;
    const result = await helper.compressImage(image, 0.5);
    if(result) {
      return result
    }
    return image
  }

  handleSubmit = async (e) => {
    e.preventDefault();
    const { first_name, last_name, phone_no, email, image } = this.state;
    const errors = helper.validate(email, first_name, last_name, phone_no);
    if (errors) {
      const {
        first_nameError,
        last_nameError,
        emailError,
        phone_noError,
      } = errors;
      this.setState({
        first_nameError,
        last_nameError,
        emailError,
        phone_noError,
      })
    }
    if (!errors) {
      this.setState({
        makeResquest: true,
      })
      const token = await `Bearer ${await helper.verifyToken()}`;
      // const data = new FormData()
      // data.append('first_name', first_name)
      // data.append('last_name', last_name)
      // data.append('email', email)
      // data.append('phone_no', phone_no)
      // if(image){
      //   const compressedImage = await this.compressImage();
      //   data.append('avatar', compressedImage)
      // }

      let data;
      let contentType;

      if (image) {
        contentType = 'multipart/form-data'
        const compressedImage = await this.compressImage();
        data = new FormData()
        data.append('first_name', first_name)
        data.append('last_name', last_name)
        data.append('email', email)
        data.append('phone_no', phone_no)
        data.append('avatar', compressedImage);
      } else {
        contentType = 'application/json'
        data = JSON.stringify({
          first_name,
          last_name,
          email,
          phone_no
        })
      }
      
      await fetch(`${baseUrl}/w`, {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Authorization': token,
          'content-type': contentType
        },
        body: data
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          })
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }

          throw response;
        })
        .then((responseJson) => {
          this.showModal();
        })
        .catch((error) => {
          error.json().then(e => {
            console.log(e);
          })
        });
    }
  }

  render() {
    const {
      first_name,
      last_name,
      phone_no,
      email,
      first_nameError,
      last_nameError,
      phone_noError,
      emailError,
      makeResquest,
      show,
      imageUrl,
    } = this.state;
    const { cancel } = this.props;
    console.log(imageUrl);
    return (
      <React.Fragment>
        <div className="add-contact">
          <p className="text-title edit-text">
            EDIT PROFILE
          </p>
          <form action="">
            <label className="img-label">
              <input
                type="file"
                style={{display:'none'}}
                ref={imageInput => (this.imageInput = imageInput)}
                onChange={this.handleImage}/>
              <img
                // src={plusIcon}
                src={imageUrl?imageUrl:plusIcon}
                alt=""
                onClick={this.openFileDialog}/>
              {/* <p className="text">Upload picture</p> */}
              {imageUrl?<p className="text" onClick={this.openFileDialog}>CHOOSE ANOTHER</p>:<p className="text">UPLOAD PHOTO</p>}
            </label>
            <label>
              <p className="text">First name</p>
              <input type="text" name="first_name" value={first_name} onChange={this.handleChange} />
              <p className="error-text">{first_nameError}</p>
            </label>
            <label>
              <p className="text">Last name</p>
              <input type="text" name="last_name" value={last_name} onChange={this.handleChange} />
              <p className="error-text">{last_nameError}</p>
            </label>
            <label>
              <p className="text">Phone number</p>
              <input type="text" name="phone_no" value={phone_no} onChange={this.handleChange} />
              <p className="error-text">{phone_noError}</p>
            </label>
            <label>
              <p className="text">Email address</p>
              <input type="text" name="email" value={email} onChange={this.handleChange} />
              <p className="error-text">{emailError}</p>
            </label>
            {/* <label>
              <p className="text">Country</p>
              <input type="text" name="phone_no" value={last_name} onChange={this.handleChange} />
              <p className="error-text">{last_nameError}</p>
            </label> */}
            <div className="button-div">
              <button className="green-button" onClick={this.handleSubmit}>
                SAVE
              </button>
              <h5 onClick={cancel}>CANCEL</h5>
            </div>
          </form>
        </div>
        <SpinnerModal show={makeResquest} />
        {
          show &&
          <Modal show={show} handleClose={this.hideModal}>
            <div className="popup">
              <img src={dropChild} alt="" />
              <p className="text">
                Profile updated successfully.
              </p>
            </div>
          </Modal>
        }
      </React.Fragment>
    )
  }
}

export default EditProfile; 
