import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import AddChild from './AddChild';
import firstTriangle from '../../assets/png/background-triangle-1.png';
import secondTriangle from '../../assets/png/background-triangle-2.png';
import plusIcon from '../../assets/svg/add-child-icon.svg';
import exploreIcon from '../../assets/svg/explore-icon.svg';
import dots from '../../assets/svg/three-dots.svg';
import helper from '../helper';

class Children extends Component {
  state = {
    options: false,
    childForm: false,
    editForm: false,
    childInfo: {},
    token: '',
  }
  async componentWillMount() {
    const token = await helper.verifyToken();
    this.setState({
      token,
    })
  }
  showOptions = (id) => {
    this.setState({
      options: true,
      optionsId: id
    })
  }
  hideOptions = () => {
    this.setState({
      options: false,
      optionsId: ''
    })
  }
  showChildForm = () => {
    this.setState({
      childForm: true,
    })
  }
  hideChildForm = () => {
    this.setState({
      childForm: false,
    }, () => {
      this.props.fetchChildren();
    })
  }
  showEditForm = (childInfo) => {
    this.setState({
      editForm: true,
      childInfo,
    })
  }
  hideEditForm = () => {
    this.setState({
      editForm: false,
      options: false,
    }, () => {
      this.props.fetchChildren();
    })
  }
  cancel = () => {
    this.setState({
      childForm: false,
      editForm: false,
      options: false,
    })
  }
  render() {
    const { options, childForm, optionsId, editForm, childInfo, token } = this.state;
    const { children } = this.props;
    return (
      <div className="children-section">
        <div className="membership-card">
          <p className="text-title">Become a member</p>
          <p className="text">As a member, you get 10% off every activity your child takes.</p>
          <img src={firstTriangle} alt="" className="triangle" />
          <img src={secondTriangle} alt="" className="triangle" />
          <Link to="/membership" style={{ textDecoration: 'none' }}>
            <button className="green-button">
              <img src={exploreIcon} alt="" />
              Explore membership
            </button>
          </Link>
        </div>
        <div className="children">
          {
            children &&
            children.map(child => {
              const { _id, last_name, first_name, avatar, previousSessions } = child;
              let url;
              if (avatar) {
                url = avatar.url
              }
              const badge = helper.createBadge(first_name, last_name);
              return (
                <div className="child-div" key={_id}>
                  <div className="child" onClick={optionsId === _id ? this.hideOptions : () => this.showOptions(_id)}>
                    {
                      url ?
                        <div className="img" style={{ backgroundImage: `url(${url}?access_token=${token})` }}></div>
                        :
                        <div className="badge">{badge}</div>
                    }
                    <p className="text-title">{first_name} {last_name}</p>
                    <p className="text">
                      {previousSessions===0 && 'No sessions Taken'}
                      {previousSessions===1 && '1 session Taken'}
                      {previousSessions>1 && `${previousSessions} sessions Taken`}
                    </p>
                    <img src={dots} alt="" className="dots" />
                    {
                      (options && optionsId === _id) &&
                      <div className="options">
                        <div onClick={() => this.showEditForm(child)}>
                          <p className="text">Edit child info</p>
                        </div>
                        <div>
                          <p className="text">Remove child</p>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              )
            })
          }

          <div className="child-div">
            <div className="child add" onClick={this.showChildForm}>
              <img src={plusIcon} alt="" />
              <p className="text-title">Add child</p>
              <p className="text">Add your child’s details to book activities.</p>
            </div>
          </div>
        </div>
        {
          childForm &&
          <AddChild hideForm={this.hideChildForm} cancel={this.cancel} action="add" />
        }
        {
          editForm &&
          <AddChild hideForm={this.hideEditForm} cancel={this.cancel} child={childInfo} action="edit" />
        }
      </div>
    )
  }
}

export default Children;
