import React, { Component } from 'react';
import check from '../../assets/svg/check.svg';
import helper from '../helper';
import Modal from '../modal/Modal';

// const badge = helper.createBadge(first_name, last_name);

class Member extends Component {
  state = {
    show: false,
    showPremium: false,
    children_id: [],
    children: [],
    selectedChild: '',
    selectedChildren: [],
  }

  componentDidMount() {
    this.props.getType('membership');
    this.listChildren();
    this.fetchPackages();
  }

  showModal = (id, name, price) => {
    this.setState({
      name,
      price
    })
    name === "Individual" ?
      this.setState({ show: true, selectedChildren: [], children_id: [], id }) :
      this.setState({ show: true, id })
  };

  showModalInit = (id, name, price) => {
    this.setState({
      name,
      price
    })
    this.setState({ show: true, selectedChildren: [], children_id: [], id })
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleMembership = (name, amount, children_id, id) => {
    this.props.getPlan(id, amount, children_id);
    this.hideModal()
  }

  handleChild = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const child_id = e.target.options[selectedIndex].getAttribute('id');
    this.setState({
      selectedChild: e.target.value,
    })
    const children_id = this.state.children_id;
    children_id.push(child_id);
  }

  handleChildren = (e) => {
    const selectedIndex = e.target.options.selectedIndex;
    const child_id = e.target.options[selectedIndex].getAttribute('id');
    const children = this.state.selectedChildren;
    const children_id = this.state.children_id;
    children.push(e.target.value);
    children_id.push(child_id);
  }

  remove = (i) => {
    const children = this.state.selectedChildren;
    // const children_id = this.state.children_id;
    const removeChild = children.splice(i, 1);
    // const removeChildId = children_id.splice(i, 1);
    this.setState({
      selectedChidren: removeChild
    })
    if (children.length === 0) {
      this.handleMembership(0, 0, [], 0)
      this.setState({
        id: 0
      })
    }
  }

  listChildren = async () => {
    const parent_id = localStorage.getItem('parent_id');
    const query = `{
          children(parent_id:"${parent_id}"){
            first_name,
            last_name,
            _id,
          }
        }`
    const data = await helper.apolloQuery(query);
    const { children } = data;
    await this.setState({
      children,
      response: true,
    })
  }

  fetchPackages = async () => {
    const query = `{
      packages{
        name
        _id
        type
        price
        ageGroup{
          min
          max
        }
        discount
        descriptions
      }
    }`
    const data = await helper.apolloQuery(query);
    await this.setState({
      packages: data.packages
    })
  }

  render() {
    const { selectedPlan } = this.props;
    const { show, id, children, selectedChildren, children_id, packages, name, price } = this.state;
    let childOption;
    if (children) {
      childOption = children.map((child, index) =>
        <option key={child._id} data-key={child._id} id={child._id}>{child.first_name} {child.last_name}</option>
      )
    }
    return (
      <div className="member">
        {
          packages &&
          packages.map((item, index) =>
            item.type === "membership" &&
            <div key={item._id} className={`option ${selectedPlan === 'standard' ? 'selected' : ''}`}>
              <div className="top">
                <p className="text">{item.name} {item.name === "Individual" ? <span>(1 Child)</span> : <span>(up to 4 kids)</span>}</p>
                <p className="text-title">₦{item.price} <span className="text">/YEAR</span></p>
              </div>
              <div className="benefit">
                <img src={check} alt="" />
                <p className="text">Access to all activities</p>
              </div>
              <div className="benefit">
                <img src={check} alt="" />
                <p className="text">10% discount on all activities</p>
              </div>
              <div className="benefit">
                <img src={check} alt="" />
                <p className="text">Access to branded Stem Cafe items</p>
              </div>
              <div className="benefit">
                <img src={check} alt="" />
                <p className="text">Access to new games before the general release date.</p>
              </div>
              <div className="benefit">
                <img src={check} alt="" />
                <p className="text">Access to information on international STEM competitions and enrolment support</p>
              </div>
              <div className="benefit">
                <img src={check} alt="" />
                <p className="text">Special projects during kid’s birthday. Celebrants can also bring up to 2 friends for a free 1-hour session.</p>
              </div>
              {
                id !== item._id ?
                  <div className="select-plan">
                    <h5 onClick={() => this.showModalInit(item._id, item.name, item.price)} >CHOOSE PLAN</h5>
                  </div> :
                  <div className="kids-div">
                    <p className="membership">Membership for</p>
                    {
                      item.name === "Individual" &&
                      <div className="kids">
                        <div className="kidRow">
                          <p className="kid-name">{selectedChildren}</p>
                          <button onClick={() => this.remove()} className="green-button btnRemove">remove</button>
                        </div>
                      </div>
                    }
                    {
                      item.name === "Premium" &&

                      selectedChildren.map((child, index) => (
                        <div className="kids" key={index}>
                          <div className="kidRow">
                            <p className="kid-name">{child}</p>
                            <button onClick={() => this.remove(index)} className="green-button btnRemove">remove</button>
                          </div>
                        </div>
                      ))
                    }
                    {
                      item.name === "Individual" ?
                        <div className="btnDiv">
                          <button onClick={() => this.showModal(item._id, item.name, item.price)} className="green-button btn">Change child</button>
                        </div> :
                        selectedChildren.length < 4 &&
                        <div className="btnDiv">
                          <button onClick={() => this.showModal(item._id, item.name, item.price)} className="green-button btn">Add another child</button>
                        </div>
                    }
                  </div>
              }
              <Modal show={show} handleClose={this.hideModal}>
                <div className="popup">
                  <div className='title'>
                    <p className="text">Select child to pay for</p>
                  </div>
                  <div className="label select-label">
                    {
                      item.name === "Individual" &&
                      <select onChange={this.handleChild}>
                        <option>Select Child</option>
                        {childOption}
                      </select>
                    }
                    {
                      item.name === "Premium" &&
                      <select onChange={this.handleChildren}>
                        <option>Select Child</option>
                        {childOption}
                      </select>
                    }
                  </div>
                  <button className="green-button" onClick={() => this.handleMembership(name, price, children_id, id)}>CONTINUE</button>
                </div>
              </Modal>
            </div>
          )
        }
      </div>
    )
  }
}

export default Member;
