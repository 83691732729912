import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import navSessions from '../../assets/svg/nav-sessions.svg';
import navSession from '../../assets/svg/nav-session.svg';
import navProfile from '../../assets/svg/nav-profile.svg';
import navCenters from '../../assets/svg/nav-centers.svg';
import navSessionActive from '../../assets/svg/nav-session-active.svg';
import navSessionsActive from '../../assets/svg/nav-sessions-active.svg';
import navProfileActive from '../../assets/svg/nav-profile-active.svg';
import navCentersActive from '../../assets/svg/nav-centers-active.svg';

class Footer extends Component {
  render() {
    const { currentPage } = this.props;
    return (
      <div className="footer">
        <Link to="/sessions" style={{ textDecoration: 'none' }} className={currentPage === "sessions"?'footer-nav-item active':'footer-nav-item'}>
          <img src={currentPage === "sessions"? navSessionsActive:navSessions} alt="" />
          <p>My Sessions</p>
        </Link>
        <Link to="/book-session" style={{ textDecoration: 'none' }} className={currentPage === "session"?'footer-nav-item active':'footer-nav-item'}>
          <img src={currentPage === "session"? navSessionActive:navSession} alt="" />
          <p>Book Session</p>
        </Link>
        <Link to="/profile" style={{ textDecoration: 'none' }} className={currentPage === "profile"?'footer-nav-item active':'footer-nav-item'}>
          <img src={currentPage === "profile"? navProfileActive:navProfile} alt="" />
          <p>Profile</p>
        </Link>
        <Link to="/centers" style={{ textDecoration: 'none' }} className={currentPage === "centers"?'footer-nav-item active':'footer-nav-item'}>
          <img src={currentPage === "centers"? navCentersActive:navCenters} alt="" />
          <p>Centers</p>
        </Link>
        {/* <div className="footer-nav-item">
          <img src={navCenters} alt="" />
          <p>Centers</p>
        </div> */}
      </div>
    )
  }
}

export default Footer;
