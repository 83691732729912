import React from "react";
import ReactDOM from "react-dom";
import "./include/bootstrap";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { createStore } from "redux";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

const initialState = {
  count: parseInt(localStorage.getItem("cart_count")) || 0,
  cart: [],
  userType: "",
  avatar: "",
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case "INCREMENT":
      localStorage.setItem("cart_count", state.count);
      return {
        ...state,
        count: state.count + 1,
      };
    case "RESET":
      return {
        ...state,
        count: 0,
      };
    case "GETCOUNT":
      const count = parseInt(localStorage.getItem("cart_count"));
      return {
        ...state,
        count,
      };
    case "ADDTOCART":
      return {
        ...state,
        cart: [...state.cart, action.payload.data],
      };
    case "SETUSERTYPE":
      return {
        ...state,
        userType: action.payload,
      };
    case "FETCHUSERTYPE":
      const userType = localStorage.getItem("user_type");
      return {
        ...state,
        userType,
      };
    case "SETAVATAR":
      return {
        ...state,
        avatar: action.payload,
      };
    default:
      return state;
  }
}

const store = createStore(reducer);

const Frontend = () => (
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

ReactDOM.render(<Frontend />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
