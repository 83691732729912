import React, { Component } from 'react';
import check from '../../assets/svg/check.svg';
import helper from '../helper';
import Modal from '../modal/Modal';

class Package extends Component {
  state = {
    show: false,
    children_id: [],
    children: [],
    selectedChild: '',
  }

  componentDidMount() {
    this.props.getType('package');
    this.listChildren();
    this.fetchPackages();
  }

  showModal = (id, name, price) => {
    this.setState({
      show: true,
      children_id: [],
      id,
      name,
      price
    });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  handleMembership = (id, amount, children_id) => {
    this.props.getPlan(id, amount, children_id);
    this.hideModal()
  }

  handleChild(e) {
    const selectedIndex = e.target.options.selectedIndex;
    const child_id = e.target.options[selectedIndex].getAttribute('id');
    this.setState({
      selectedChild: e.target.value,
      // children_id: []
    })
    const children_id = this.state.children_id;
    children_id.push(child_id);
  }

  listChildren = async () => {
    const parent_id = localStorage.getItem('parent_id');
    const query = `{
          children(parent_id:"${parent_id}"){
            first_name,
            last_name,
            _id,
          }
        }`
    const data = await helper.apolloQuery(query);
    const { children } = data;
    await this.setState({
      children,
      response: true,
    })
  }

  remove = (i) => {
    const children_id = [];
    // const removeChildId = children_id.splice(i, 1);
    this.setState({
      selectedChild: ""
    })
    console.log(children_id)
    if (children_id.length === 0) {
      this.handleMembership(0, 0, [])
      this.setState({
        id: 0
      })
    }
  }

  fetchPackages = async () => {
    const query = `{
      packages{
        name
        _id
        type
        price
        ageGroup{
          min
          max
        }
        discount
        descriptions
      }
    }`
    const data = await helper.apolloQuery(query);
    await this.setState({
      packages: data.packages
    })
  }

  render() {
    const { selectedPlan } = this.props;
    const { show, children, selectedChild, children_id, packages, id, price } = this.state;
    let childOption;
    if (children) {
      childOption = children.map((child, index) =>
        <option key={child._id} data-key={child._id} id={child._id}>{child.first_name} {child.last_name}</option>
      )
    }
    return (
      <div className="package">
        {
          packages &&
          packages.map((item, index) =>
            item.type === "package" &&
            <div key={item._id} className={`option ${selectedPlan === 'unlimited' ? 'selected' : ''}`}>
              <div className="top">
                <p className="text package-name">{item.name}</p>
                <p className="text-title">₦{item.price}<span className="text">/MONTH</span></p>
              </div>
              <div className="benefit">
                <img src={check} alt="" />
                <p className="text">Everyday of the week</p>
              </div>
              <div className="benefit">
                <img src={check} alt="" />
                <p className="text">Up to 4hours daily</p>
              </div>
              <div className="benefit">
                <img src={check} alt="" />
                <p className="text">All ages</p>
                {
                  selectedChild === '' &&
                  <h5 onClick={() => this.showModal(item._id, item.name, item.price)}>CHOOSE PLAN</h5>
                }
              </div>
              {
                selectedChild !== '' &&
                <div className="kids-div">
                  <p className="membership">Membership for</p>
                  <div className="kids">
                    <div className="kidRow">
                      <p className="kid-name">{selectedChild}</p>
                      <button onClick={() => this.remove()} className="green-button btnRemove">remove</button>
                    </div>
                  </div>
                  <div className="btnDiv">
                    <button onClick={() => this.showModal(item._id, item.name, item.price)} className="green-button btn">Change child</button>
                  </div>
                </div>
              }
              <Modal show={show} handleClose={this.hideModal}>
                <div className="popup">
                  <div className='title'>
                    <p className="text">Select child to pay for</p>
                  </div>
                  <div className="label select-label">
                    <select onChange={this.handleChild.bind(this)}>
                      <option>Select Child</option>
                      {childOption}
                    </select>
                  </div>
                  <button className="green-button" onClick={() => this.handleMembership(id, price, children_id)}>CONTINUE</button>
                </div>
              </Modal>
            </div>
          )
        }
      </div>
    )
  }
}

export default Package;
