import React from 'react';
import spinner from '../../assets/svg/spinner.svg';

class Modal extends React.Component{
    render(){
        const {show, text} = this.props;
        const showHideClassname = show ? "spinner-modal display-block" : "spinner-modal display-none";
        return(
            <div className={showHideClassname}>
                {text && <p style={{fontSize:"16px",fontWeight:400,color:"#FFA300"}}>{text}</p>}
                <img src={spinner} alt='Loading' width='70'/>
            </div>
        )
    }
}

export default Modal;