import React, { Component } from 'react';
import queryString from 'query-string';
import Footer from '../footer/footer';
import helper from '../helper';
import left from '../../assets/svg/left.svg';
import spinner from '../../assets/svg/spinner.svg';
import Modal from '../modal/Modal';
import ImageCarousel from '../carousel/ImageCarousel';

export default class SeeMore extends Component {
  state = {
    prevPath: '/sessions/previous-sessions',
    screenOffset: {},
    carouselImages: [],
    data: null
  }
  componentDidMount() {
    const { prevPath, screenOffset, search } = this.props.location
    this.setState({ prevPath: prevPath? prevPath : this.state.prevPath, screenOffset })
    window.scrollTo(0, 0);
    const { match: { params } } = this.props;
    const { id } = params;
    this.fetchSessionDetails(id);
    // let queryObj = {};
    let queryObj = queryString.parse(search);
    if(queryObj && Object.keys(queryObj).length !== 0){
      const { token } = queryObj;
      localStorage.setItem('access_token', token);
      console.log(token);
    }
  }
  goBack = () => {
    const { prevPath, screenOffset } = this.state
    this.props.history.push({
      pathname: prevPath,
      screenOffset: screenOffset
    })
    console.log(prevPath);
  }
  fetchSessionDetails = async (id) => {
    const query = `{
      session(id: "${id}"){
        _id,
        child{
          first_name,
          last_name,
          previousSessions,
          avatar{
            url
          }
        },
        activity{
          name,
          description,
          ageGroup{
            min
          },
          category{
            name
          }
        },
        center{
          name,
          address,
          timezone
        },
        report
        dropTime,
        pickupTime,
        start,
        end,
        media{url},
      }
    }`
    const data = await helper.apolloQuery(query);
    const { session } = data;
    await this.setState({
      data: session,
      response: true,
    }, () => {this.setImageCarousel()})
  }
  showCarousel = (index) => {
    this.setState({
      index,
      showModal: true
    })
  }
  closeCarousel = () => {
    this.setState({
      showModal: false
    })
  }
  setImageCarousel = () => {
    const {data} = this.state;
    let images = []
    if(data.media){
      data.media.forEach((item, index) => {
        const { url } = item;
        const filename = url.split('/').pop();
        const isImage = filename.match(/.(jpg|jpeg|png|gif)$/i);
        if(isImage) images=[...images, item]
      })
    }
    this.setState({
      carouselImages: [...this.state.carouselImages, ...images],
    })
  }
  render() {
    const { data, response, index, showModal, carouselImages } = this.state;
    const media = data && data.media;
    const child = data && data.child;
    const activity = data && data.activity;
    const date = data && helper.formatDateTime(data.start, 'dddd DD/MM/YYYY', data.center.timezone);
    const time = data && helper.formatDateTime(data.start, 'hh:mma', data.center.timezone);
    const duration = data && (data.end - data.start) / 60 / 1000 / 60;
    const unit = (duration === 1) ? 'Hour' : 'Hours';
    const token = localStorage.getItem('access_token');
    return (
      <React.Fragment>
        <div className='back-panel'>
          <span onClick={this.goBack}>
            <img className='left' src={left} alt="" />
            <p>Back to My Sessions</p>
          </span>
        </div>
        {
          !response &&
          <div className="spinner-div">
            <img src={spinner} alt='Loading' width='70' />
          </div>
        }
        {
          data &&
          <React.Fragment>
            <div className="see-more">
              <div className='more-unit'>
                <div className='more-top'>
                  <div>
                    <p className='text'>{activity !== null ? activity.name : `${duration} ${unit} Booked`}</p>
                    <p className='mini-text'>Duration • {duration} {unit}</p>
                  </div>
                </div>
                <div className='detail-btns'>
                  {
                    activity && activity.catergory &&
                    <div className='detail-btn'>
                      <p className='text'>{activity.catergory.name}</p>
                    </div>
                  }
                  {
                    activity && activity.ageGroup &&
                    <div className='detail-btn'>
                      <p className='text'>{activity.ageGroup.min}+</p>
                    </div>
                  }
                  <div className='detail-btn'>
                    <p className='text'>{data.center.name}</p>
                  </div>
                </div>
                <div className='info'>
                  <div className='date'>
                    <p className="text">Date taken</p>
                    <p className="text-title">{date}</p>
                  </div>
                  <div className='time'>
                    <p className="text">Time</p>
                    <p className="text-title">{time}</p>
                  </div>
                </div>
              </div>
              <div className="more-main">
                <p className="text title">{activity !== null ? activity.name : `${duration} ${unit} Booked`}</p>
                <p className="text">{activity !== null ? activity.description : `This session was booked for ${duration} ${unit}, open to any activity.`}</p>

                <p className="text title">See {child.first_name} in action</p>
                <p className="text">{data.report}</p>
                {
                  media && media.length > 0 &&
                  media.map((item, index) => {
                    const { url } = item;
                    const filename = url.split('/').pop();
                    const isImage = filename.match(/.(jpg|jpeg|png|gif)$/i);
                    return (
                      !isImage &&
                      <div className="vid-div" key={index}>
                        <video ref="vidRef" controls={true} width='100%' type="video/mp4">
                          <source src={`${url}?access_token=${token}`} type="video/mp4" />
                        </video>
                      </div>
                    )
                  })
                }
                <div className="more-img-div-container">
                  {
                    media && media.length > 0 &&
                    media.map((item, index) => {
                      const { url } = item;
                      const filename = url.split('/').pop();
                      const isImage = filename.match(/.(jpg|jpeg|png|gif)$/i);
                      return (
                        isImage &&
                        <div className="img-div" key={index} onClick={() => this.showCarousel(index)}>
                          <img src={`${url}?access_token=${token}`} alt="" />
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </React.Fragment>
        }
        <Footer currentPage="sessions" />
        <Modal show={showModal} handleClose={this.closeCarousel}>
          {
            showModal &&
            <ImageCarousel
              images={carouselImages}
              selected={index}
            />
          }
        </Modal>
      </React.Fragment>
    )
  }
}
