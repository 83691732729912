import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import moment from "moment";
import 'moment-timezone';
import QRCode from 'qrcode.react';
import noSession from '../../assets/svg/no-sessions.svg';
import dropChild from '../../assets/svg/drop-child.svg';
import spinner from '../../assets/svg/spinner.svg';
import spin from '../../assets/svg/spin-white.svg';
import noChild from '../../assets/svg/no-child-added.svg';
import Modal from '../modal/Modal';
import helper from '../helper';
import closeIcon from '../../assets/svg/close-icon.svg';
import 'font-awesome/css/font-awesome.min.css';
import { baseUrl } from '../url';
import {
  WhatsappShareButton,
  TwitterShareButton,
  TelegramShareButton,
  WhatsappIcon,
  TwitterIcon,
  TelegramIcon
} from 'react-share';

class BookedSessions extends Component {
  state = {
    show: false,
    dropped: false,
    showQr: false,
    showNotDate: false,
    showCopy: false,
    id: 0,
    code: 0,
    share: false
  }
  componentDidMount() {
    const parent_id = localStorage.getItem('parent_id');
    const qs = `parent_id:"${parent_id}", period:UPCOMING`;
    this.props.getSessions(qs);
  }

  copyToClipboard = (text) => {
    this.setState({
      showQr: false,
      showCopy: true
    })
    var dummy = document.createElement("input");
    document.body.appendChild(dummy);
    dummy.setAttribute('value', text);
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  }

  handleChild = async (id, type, date) => {
    const today = moment().format('ddd DD/MM/YYYY');
    if (type === 'dropoff' && today !== date) {
      return this.setState({
        showNotDate: true
      })
    }
    this.setState({ dropId: id })
    const token = await `Bearer ${await helper.verifyToken()}`;
    await fetch(`${baseUrl}/w/sessions/${id}/child-services/${type}`, {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': token
      },
    })
      .then((response) => {
        if (helper.range(200, 299).indexOf(response.status) !== -1) {
          return response.json();
        }
      })
      .then((resjson) => {
        console.log(resjson)
        if (type === 'pickup') {
          this.setState({
            code: resjson.code,
            showQr: true,
            dropped: false
          })
        } else if (type === 'dropoff') {
          this.props.getSessions('UPCOMING');
          this.setState({
            dropped: true
          })
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  hideModal = () => {
    this.setState({ show: false });
  };
  hideQrModal = () => {
    this.setState({ showQr: false });
  };
  hideNotDateModal = () => {
    this.setState({ showNotDate: false });
  };
  hideCopyModal = () => {
    this.setState({ showCopy: false });
  };
  expand = (id) => {
    this.setState({
      id,
    })
  }
  render() {
    const { show, showNotDate, showCopy, showQr, id, dropId, share, code } = this.state;
    const { sessions, response, hasChildren } = this.props;
    const title = `Stem Cafe pickup code: ${code}`;
    const from = JSON.parse(localStorage.getItem('from'));
    if(from){
      const { pathname } = from;
      localStorage.removeItem('from');
      return <Redirect to={pathname} />
    }
    return (
      <div className="booked-sessions">
        {
          !response &&
          <div className="spinner-div">
            <img src={spinner} alt='Loading' width='70' />
          </div>
        }
        {
          sessions.length > 0 && response && hasChildren &&
          <React.Fragment>
            {
              sessions.map(session => {
                const { _id, activity, child, center, start, end, dropTime } = session;
                const { first_name } = child;
                let activity_name;
                let description;
                if (activity) {
                  activity_name = activity.name;
                  description = activity.description;
                }
                const { name: center_name, address, timezone } = center;
                const date = helper.formatDateTime(start, 'ddd DD/MM/YYYY', timezone);
                const time = helper.formatDateTime(start, 'hh:mma', timezone);
                const duration = (end - start)/ 60 / 1000 / 60;
                const unit = (duration === 1) ? 'Hour' : 'Hours';
                return (
                  <div className="session" key={_id}>
                    <div className="top">
                      <div>
                        <p className="text-title">{activity ? activity_name : `${duration} ${unit} Booked`}</p>
                        <p className="text">Duration • {duration} {unit}</p>
                      </div>
                      {
                        id === _id &&
                        <button className="orange-button" onClick={() => this.expand(0)}>CLOSE</button>
                      }
                      {
                        id !== _id &&
                        <button className="orange-button" onClick={() => this.expand(_id)}>VIEW DETAILS</button>
                      }

                    </div>
                    <div className="tags">
                      <div className="tag">Robotics</div>
                      <div className="tag">8+</div>
                    </div>
                    <div className="info">
                      <div>
                        <p className="text">Child Registered</p>
                        <p className="text-title">{first_name}</p>
                      </div>
                      <div>
                        <p className="text">Date</p>
                        <p className="text-title">{date}</p>
                      </div>
                      <div>
                        <p className="text">Time</p>
                        <p className="text-title">{time}</p>
                      </div>
                    </div>
                    <div className="button-div">
                      {/* <button className="green-button">DROP OFF MY CHILD</button> */}
                      {
                        dropTime === null ?
                          <button className="green-button" onClick={() => this.handleChild(_id, 'dropoff', date)}>{dropId === _id && <img src={spin} style={{ marginTop: -5, marginLeft: -15 }} alt='Loading' width='30' />}DROP OFF MY CHILD</button> :
                          <button className="dark-button" onClick={() => this.handleChild(_id, 'pickup', date)}>PICKUP MY CHILD</button>
                      }
                    </div>
                    {
                      id === _id &&
                      <React.Fragment>
                        <div>
                          {
                            activity &&
                            <div className="more-info">
                              <p className="text-title">ABOUT ACTIVITY</p>
                              <p className="text">
                                {description}
                              </p>
                            </div>
                          }
                          <div className="more-info">
                            <p className="text-title">CENTER ADDRESS</p>
                            <p className="text">
                              {center_name}, {address}.
                            </p>
                          </div>
                          <div className="more-info">
                            <p className="text-title">CENTER CONTACT</p>
                            <p className="text">
                              +234 802 609 3012
                            </p>
                          </div>
                        </div>
                        <div className="contact">
                          <p className="text">Need to change your booking?</p>
                          <a href="tel:+23408094003967">
                            <button className="orange-button">
                              Call Us
                            </button>
                          </a>
                        </div>
                      </React.Fragment>
                    }
                  </div>
                )
              })
            }
            {
              share &&
              <div className="share-panel">
                <img src={closeIcon} alt="" className="close-icon" onClick={() => { this.setState({ share: false }) }} />
                <p className="share-text">Share via</p>
                <div className="icon-div">
                  <div className="share-row">
                    <WhatsappShareButton
                      url={code}
                      title={title}
                      separator=": "
                      className="share-icon">
                      <WhatsappIcon size={70} round />
                      <p className="share-label">whatsapp</p>
                    </WhatsappShareButton>
                    <TwitterShareButton
                      url={code}
                      title={title}
                      separator=": "
                      className="share-icon">
                      <TwitterIcon size={70} round />
                      <p className="share-label">Twitter</p>
                    </TwitterShareButton>
                    <TelegramShareButton
                      url={code}
                      title={title}
                      separator=": "
                      className="share-icon">
                      <TelegramIcon size={70} round />
                      <p className="share-label">Telegram</p>
                    </TelegramShareButton>
                  </div>
                </div>
              </div>
            }
          </React.Fragment>
        }
        {
          sessions.length === 0 && response && hasChildren &&
          <div className="no-sessions">
            <img src={noSession} alt="" />
            <p className="text">No upcoming sessions</p>
            <Link to="/book-session" style={{ textDecoration: 'none' }}>
              <button className="green-button">BOOK SESSION</button>
            </Link>
          </div>
        }
        {
          !hasChildren && response &&
          <div className="no-sessions">
            <img src={noChild} alt="" />
            <p className="text">You don't have any child registered yet. click the button below to get started</p>
            <Link to="/profile" style={{ textDecoration: 'none' }}>
              <button className="green-button">GET STARTED</button>
            </Link>
          </div>
        }
        <Modal show={show} handleClose={this.hideModal}>
          <div className="popup">
            <img src={dropChild} alt="" />
            <p className="text">Thank you! Your child was dropped off at <span>1:30pm</span> today</p>
          </div>
        </Modal>
        <Modal show={showNotDate} handleClose={this.hideNotDateModal}>
          <div className="popup">
            <i className="fa fa-times-circle close"/>
            <p className="text">Can't drop off child because booking date is not today</p>
          </div>
        </Modal>
        <Modal show={showCopy} handleClose={this.hideCopyModal}>
          <div className="popup">
            <p className="text-copy">Code copied</p>
          </div>
        </Modal>
        <Modal show={showQr} handleClose={this.hideQrModal}>
          <div className="popup">
            <p className="text">Show details to pick up child</p>
            <QRCode
              value={`${code}`}
              size={150}
              bgColor={"#ffffff"}
              fgColor={"#000000"}
              level={"Q"}
              includeMargin={false}
              renderAs={"svg"}
            />
            <div className="qr-value">
              <p className="text">{code}</p>
            </div>
            <div className="someone-pickup">
              <p className="text">Is someone else picking up your child?</p>
              <div className="btn-div">
                <div>
                  <button className="orange-button" onClick={() => { this.setState({ share: true }) }}>SHARE QR CODE</button>
                </div>
                <div>
                  <button className="orange-button" onClick={() => { this.copyToClipboard(code) }}>COPY CODE</button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}

export default BookedSessions;
