import React, { Component } from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "../carousel/carousel.css";
import { Carousel } from 'react-responsive-carousel';

export default class ImageCarousel extends Component {
  render() {
    const {images, selected} = this.props;
    const token = localStorage.getItem('access_token');
    return (
      <div>
        <Carousel showStatus={false} selectedItem={selected} showIndicators={false} swipeable={true}>
          {
            images.map((image, index) => {
              const { url } = image;
              const filename = url.split('/').pop();
              const isImage = filename.match(/.(jpg|jpeg|png|gif)$/i);
              return(
                isImage &&
                <div key={index}>
                  <img src={`${url}?access_token=${token}`} alt="" />
                </div>
              )
            })
          }
        </Carousel>
      </div>
    )
  }
}
