import React, { Component } from 'react';
import Footer from '../footer/footer';
import Children from './Children';
import AddChild from './AddChild';
import PrimaryContact from './PrimaryContact';
import EditProfile from './EditProfile';
import firstTriangle from '../../assets/png/background-triangle-1.png';
import secondTriangle from '../../assets/png/background-triangle-2.png';
import plusIcon from '../../assets/svg/plus-icon.svg';
import noChildIcon from '../../assets/svg/no-child.svg';
import spinner from '../../assets/svg/spinner.svg';
import TabView from '../TabView/TabView';
import helper from '../helper';
import { withRouter } from 'react-router-dom';
import Modal from '../modal/Modal';

class Profile extends Component {
  state = {
    user: {},
    children: [],
    childForm: false,
    response: false,
    show: false,
    editProfile: false,
  }

  async componentWillMount(){
    const token = await helper.verifyToken();
    this.setState({
      token,
    })
  }

  componentDidMount() {
    this.fetchProfile();
    this.fetchChildren();
  }

  fetchProfile = async () => {
    const query = `{
      user{
        _id,
        last_name,
        first_name,
        ...on Parent {
          avatar{
            url
          }
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    await this.setState({
      user: data.user
    })
  }

  fetchChildren = async () => {
    const parent_id = localStorage.getItem('parent_id');
    const query = `{
      children(parent_id:"${parent_id}"){
        first_name,
        last_name,
        _id,
        previousSessions,
        avatar{
          url
        }
      }
    }`
    const data = await helper.apolloQuery(query);
    const { children } = data;
    await this.setState({
      children,
      response: true,
    })
  }

  showLogOutModal = () => {
    this.setState({
      show: true,
    })
  }

  hideLogOutModal = () => {
    this.setState({ show: false });
  };

  showChildForm = () => {
    this.setState({
      childForm: true,
    })
  }
  hideChildForm = () => {
    this.setState({
      childForm: false,
    }, () => {
      this.fetchChildren();
    })
  }

  showEditProfile = () => {
    this.setState({
      editProfile: true,
    })
  }
  hideEditProfile = () => {
    this.setState({
      editProfile: false,
    },() => {this.fetchProfile()})
  }
  handleCancel = () => {
    this.setState({
      editProfile: false,
    })
  }

  logOut = () => {
    localStorage.clear();
    this.props.history.push({
      pathname: '/signin',
    })
  }

  render() {
    const { children, childForm, user, response, show, editProfile, token } = this.state;
    const { last_name, first_name, avatar } = user;
    let url;
    if(avatar){
      url = avatar.url
    }
    const badge = helper.createBadge(first_name, last_name);
    return (
      <React.Fragment>
        <div className="profile">
          <div className="top">
            <div className="name-badge">
              {
                url?
                <div className="img" style={{backgroundImage:`url(${url}?access_token=${token})`}}></div>
                :
                <div className="badge">{badge}</div>
              }
              <p>{first_name} {last_name}</p>
            </div>
            <button className="orange-button" onClick={this.showEditProfile}>EDIT PROFILE</button>
          </div>
          {
            !response &&
            <div className="spinner-div">
              <img src={spinner} alt='Loading' width='70' />
            </div>
          }
          {
            editProfile && 
            <EditProfile cancel={this.handleCancel} closeForm={this.hideEditProfile}/>
          }
          {
            !children && response &&
            <React.Fragment>
              <div className="add-details">
                <p className="text-title">Welcome, please add your child’s details to book an activity</p>
                <p className="text">As a member, you get 10% off every activity your child takes</p>
                <button className="green-button" onClick={this.showChildForm}>
                  <img src={plusIcon} alt="" />
                  Add a child to get started
                </button>
                <img src={firstTriangle} alt="" className="triangle" />
                <img src={secondTriangle} alt="" className="triangle" />
              </div>
              <div className="no-child">
                <img src={noChildIcon} alt="" />
                <p className="text-title">No child added</p>
                <p className="text">You haven’t added your child yet.</p>
              </div>
              {
                childForm &&
                <AddChild hideForm={this.hideChildForm} />
              }
            </React.Fragment>
          }
          {
            children && response &&
            <React.Fragment>
              <TabView>
                <p>My Children</p>
                <span>
                  <Children fetchChildren={this.fetchChildren} children={children} />
                </span>
                <p>Primary Contact</p>
                <span>
                  <PrimaryContact />
                </span>
              </TabView>
            </React.Fragment>
          }
          <div className='logout-div'>
            <button className="green-button" onClick={this.showLogOutModal}>LOGOUT</button>
          </div>
          <Modal show={show} handleClose={this.hideLogOutModal}>
            <div className="popup">
              <p className="text">Are you sure you want to log out?</p>
              <div className={'modalBtn'}>
                <button className="green-button outline" onClick={this.hideLogOutModal}>NO</button>
                <button className="green-button" onClick={this.logOut}>YES</button>
              </div>
            </div>
          </Modal>
        </div>
        <Footer currentPage="profile" />
      </React.Fragment>
    )
  }
}

export default withRouter(Profile);
