import React from 'react';

function Top(props) {
    return (
        <div className='top-panel'>
            <p><strong>{props.title}</strong></p>
        </div>
    )
}

export default Top;
