import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from '../modal/Modal';
import { withRouter } from 'react-router-dom';
import helper from '../helper';

class BookSession extends Component {
  state = {
    show: false,
    child: false,
    weekendExplorer: false,
    weekendExplorerModal: false,
    selectedChild: this.props.selectedChild,
    selectedCenter: this.props.selectedCenter,
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  showChild = () => {
    const { selectedChild, selectedCenter } = this.props;
    const errors = helper.validateChildInfo(selectedChild, selectedCenter);
    if (errors) {
      const { childError, centerError } = errors;
      this.setState({
        childError,
        centerError,
      })
    }
    if (!errors) {
      this.setState({
        child: true,
        show: false,
      }, () => {
        this.props.enable();
      });
    }
  }

  showExplorerModal = () => {
    this.setState({ weekendExplorerModal: true })
  }

  hideExplorerModal = () => {
    this.setState({ weekendExplorerModal: false })
  }

  checkChildren(children) {
    return children && children.length?true:false;
  }

  render() {
    const { show, child, weekendExplorerModal, weekendExplorer, childError, centerError } = this.state;
    const { handleCenter, handleChild, selectedChild, children, centers, selectedCenter } = this.props;
    const hasChildren = this.checkChildren(children);
    let childOption;
    let centerOption;
    if (children) {
      childOption = children.map((child, index) =>
        <option key={child._id} data-key={child._id}>{child.first_name} {child.last_name}</option>
      )
    }
    if (centers) {
      centerOption = centers.map((center, index) =>
        <option key={center._id} data-key={center._id}>{center.name}</option>
      )
    }
    return (
      <React.Fragment>
        <div className='select-card'>
          {
            hasChildren &&
            <React.Fragment>
              {
                !child &&
                <button className="green-button" onClick={this.showModal}>SELECT CHILD INFO </button>
              }
              {
                child &&
                <div>
                  <div className='select-card-details'>
                    <div className='details'>
                      <p className='mini-text'>CHILD</p>
                      <p className='text'>{selectedChild}</p>
                    </div>
                    <div className='details'>
                      <p className='mini-text'>CENTER</p>
                      <p className='text'>{selectedCenter}</p>
                    </div>
                    <div className='details-btn'>
                      <button className="orange-button" onClick={this.showModal}>CHANGE</button>
                    </div>
                  </div>
                  {
                    weekendExplorer &&
                    <div className='weekend-explorer'>
                      <p className='text'>{selectedChild.split(' ')[0]} has a <span onClick={this.showExplorerModal}>Weekend Explorer</span> pass</p>
                    </div>
                  }
                </div>
              }
            </React.Fragment>
          }
          {
            !hasChildren &&
            <div className="no-sessions">
              <p className="text">You don't have any child registered yet. click the button below to get started</p>
              <Link to="/profile" style={{ textDecoration: 'none' }}>
                <button className="green-button">GET STARTED</button>
              </Link>
            </div>
          }
        </div>
        <Modal show={show} handleClose={this.hideModal}>
          <div className="popup">
            <div className='title'>
              <p className="text">Select a child to book session for</p>
            </div>
            <div className="label select-label">
              <select onChange={handleChild}>
                <option>Select Child</option>
                {childOption}
              </select>
              {!selectedChild && <p className="error-text">{childError}</p>}
            </div>
            <div className="label select-label">
              <select onChange={handleCenter}>
                <option>Select Centre</option>
                {centerOption}
              </select>
              {!selectedCenter && <p className="error-text">{centerError}</p>}
            </div>
            <button className="green-button" onClick={this.showChild}>CONTINUE</button>
          </div>
        </Modal>
        <Modal show={weekendExplorerModal} handleClose={this.hideExplorerModal}>
          <div className="popup-explorer">
            <div className='title'>
              <p className="text">Weekend Explorer Pass gives your child access to the Cafe on Saturdays and Sundays up to 4 hours daily</p>
            </div>

            <button className="green-button" onClick={this.hideExplorerModal}>OK</button>
          </div>
        </Modal>
      </React.Fragment>
    )
  }
}

export default withRouter(BookSession);
