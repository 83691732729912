import React, { Component } from "react";
import helper from "../../helper";
import { baseUrl } from "../../url";
import uploadIcon from "../../../assets/svg/upload-icon.svg";
import Footer from "../footer/Footer";
import left from "../../../assets/svg/left.svg";
import SpinnerModal from "../../modal/SpinnerModal";
import Modal from "../../modal/Modal";
import checkIcon from "../../../assets/svg/drop-child.svg";
// import imageLoader from '../../../assets/svg/spinner-bg.svg';

export default class EditEducatorProfile extends Component {
  state = {
    last_name: "",
    first_name: "",
    phone_no: "",
    email: "",
    avatar: "",
    makeResquest: false,
    messageModal: false,
    image: "",
    imageUrl: "",
  };
  componentDidMount() {
    this.fetchProfile();
  }
  goBack = () => {
    this.props.history.goBack();
  };
  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
      [e.target.name + "Error"]: "",
    });
  };
  fetchProfile = async () => {
    const query = `{
      user{
        _id,
        last_name,
        first_name,
        phone_no,
        email,
        ...on EducatorType {
          avatar{
            url
          }
        }
      }
    }`;
    const data = await helper.apolloQuery(query);
    const token = await helper.verifyToken();
    const { user } = data;
    const { last_name, first_name, phone_no, email, avatar } = user;
    let formattedUrl = "";
    if (avatar) {
      const { url } = avatar;
      formattedUrl = `${url}?access_token=${token}`;
    }
    await this.setState({
      last_name,
      first_name,
      phone_no,
      email,
      imageUrl: formattedUrl,
    });
  };
  validate = () => {
    const { last_name, first_name, phone_no, email } = this.state;
    const last_nameError = !last_name ? "Last name cannot be empty" : null;
    const first_nameError = !first_name ? "First name cannot be empty" : null;
    const phone_noError = !phone_no
      ? "Please enter a valid phone number"
      : null;
    const emailError = !email ? "Please enter a valid email address" : null;
    if (last_nameError || first_nameError || phone_noError || emailError) {
      const error = true;
      this.setState({
        last_nameError,
        first_nameError,
        phone_noError,
        emailError,
      });
      return error;
    }
  };
  inputClick = () => {
    this.imageInput.click();
  };
  handleImage = (e) => {
    const file = e.target.files[0];
    this.setState({
      image: file,
      imageUrl: URL.createObjectURL(file),
    });
  };

  compressImage = async () => {
    const { image } = this.state;
    const result = await helper.compressImage(image, 0.5);
    if (result) {
      return result;
    }
    return image;
  };

  handleSubmit = async () => {
    const error = this.validate();
    if (!error) {
      this.setState({
        makeResquest: true,
      });
      const { last_name, first_name, phone_no, email, image } = this.state;
      const token = `Bearer ${localStorage.getItem("access_token")}`;
      const url = `${baseUrl}/e`;
      const data = new FormData();
      data.append("first_name", first_name);
      data.append("last_name", last_name);
      data.append("email", email);
      data.append("phone_no", phone_no);
      if (image) {
        const compressedImage = await this.compressImage();
        data.append("avatar", compressedImage);
      }
      fetch(url, {
        method: "PUT",
        headers: {
          Authorization: token,
          Accept: "application/json",
        },
        body: data,
      })
        .then((response) => {
          this.setState({
            makeResquest: false,
          });
          if (helper.range(200, 299).indexOf(response.status) !== -1) {
            return response.json();
          }

          throw response;
        })
        .then((responseJson) => {
          if (responseJson) {
            this.setState({
              serverMessage: responseJson.message,
              messageModal: true,
            });
          }
        })
        .catch((error) => {
          error.json().then((e) => {
            console.log(e);
            this.setState({
              errorMessage: e.message,
              messageModal: true,
            });
          });
        });
    }
  };
  closeModal = () => {
    const { serverMessage } = this.state;
    this.setState(
      {
        messageModal: false,
      },
      () => {
        if (serverMessage) {
          this.goBack();
        }
      }
    );
  };
  render() {
    const {
      last_name,
      first_name,
      phone_no,
      email,
      last_nameError,
      first_nameError,
      phone_noError,
      emailError,
      makeResquest,
      messageModal,
      errorMessage,
      serverMessage,
      imageUrl,
    } = this.state;
    return (
      <React.Fragment>
        <div className="back-panel">
          <span onClick={this.goBack}>
            <img className="left" src={left} alt="" />
            <p>Edit Profile</p>
          </span>
        </div>
        <div className="edit-educator-profile">
          <div className="image-div">
            <input
              type="file"
              style={{ display: "none" }}
              ref={(imageInput) => (this.imageInput = imageInput)}
              onChange={this.handleImage}
            />
            <img
              src={imageUrl ? imageUrl : uploadIcon}
              alt=""
              onClick={() => this.inputClick()}
            />
          </div>
          <label>
            <p className="text">First name</p>
            <input
              type="text"
              name="first_name"
              value={first_name}
              onChange={this.handleChange}
            />
            <p className="error-text">{first_nameError}</p>
          </label>
          <label>
            <p className="text">Last name</p>
            <input
              type="text"
              name="last_name"
              value={last_name}
              onChange={this.handleChange}
            />
            <p className="error-text">{last_nameError}</p>
          </label>
          <label>
            <p className="text">Phone number</p>
            <input
              type="text"
              name="phone_no"
              value={phone_no}
              onChange={this.handleChange}
            />
            <p className="error-text">{phone_noError}</p>
          </label>
          <label>
            <p className="text">Email address</p>
            <input
              type="text"
              name="email"
              value={email}
              onChange={this.handleChange}
            />
            <p className="error-text">{emailError}</p>
          </label>
          {/* <label>
              <p className="text">Country</p>
              <input type="text" name="phone_no" value={last_name} onChange={this.handleChange} />
              <p className="error-text">{last_nameError}</p>
            </label> */}
          <div className="button-div">
            <button className="green-button" onClick={this.handleSubmit}>
              SAVE
            </button>
          </div>
        </div>
        <Footer />
        <Modal show={messageModal} handleClose={this.closeModal}>
          <div className="popup end-session-modal">
            {serverMessage && <img src={checkIcon} alt="" />}
            {errorMessage && <h3 className="small-margin">{errorMessage}</h3>}
            {serverMessage && <h3 className="small-margin">{serverMessage}</h3>}
            <div className="button-div">
              <button className="green-button" onClick={this.closeModal}>
                OK
              </button>
            </div>
          </div>
        </Modal>
        <SpinnerModal show={makeResquest} />
      </React.Fragment>
    );
  }
}
