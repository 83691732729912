import React, { Component } from "react";
import down from "../../assets/svg/orange-down.svg";
import up from "../../assets/svg/up.svg";
import spinner from "../../assets/svg/spinner.svg";
import { connect } from "react-redux";
import DatePicker from "react-datepicker";
import helper from "../helper";

import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "moment-timezone";

class Activity extends Component {
  state = {
    activities: [],
    response: false,
    open: "",
    close: "",
  };
  componentDidMount() {
    this.listActivities();
  }

  componentWillReceiveProps(nextProps) {
    const { open, close } = nextProps.openingHours[0];
    this.setState({
      open,
      close,
    });
  }

  listActivities = async () => {
    const query = `{
      activities(includeCenter:true){
        name
        _id,
        description,
        duration{
          unit,
          time,
        },
        category{
          name
        },
        ageGroup{
          min
          max
        }
      }
    }`;
    const data = await helper.apolloQuery(query);
    const { activities } = data;
    await this.setState({
      activities,
      response: true,
    });
  };

  addToCart = () => {
    this.props.dispatch({ type: "INCREMENT" });
  };

  render() {
    const { activities, response, open, close } = this.state;
    const {
      pickActivity,
      date,
      handleDate,
      time,
      handleTime,
      book,
      activity_id,
    } = this.props;
    const openFormatted = moment(open, "ha");
    const closeFormatted = moment(close, "ha");
    return (
      <React.Fragment>
        {!response && (
          <div className="spinner-div">
            <img src={spinner} alt="Loading" width="70" />
          </div>
        )}
        {activities &&
          response &&
          activities.map((activity) => {
            const { _id, name, description, duration, category, ageGroup } =
              activity;
            const categoryName = category ? category.name : "n/a";
            const ageGroupMax =
              ageGroup && ageGroup.max ? `-${ageGroup.max}` : "+";
            const ageGroupBracket = ageGroup
              ? `${ageGroup.min}${ageGroupMax}`
              : "n/a";
            return (
              <div className="activity-div" key={_id}>
                <div className="title" name="one">
                  <div>
                    <p className="text">{name}</p>
                    <p className="text small-with-opacity">
                      Duration • {duration.time} Hours{" "}
                    </p>
                  </div>
                  {activity_id === _id ? (
                    <button
                      className="button-close"
                      onClick={() => pickActivity(0, 0)}
                    >
                      CLOSE
                      <img className="down" src={up} alt="" />
                    </button>
                  ) : (
                    <button
                      className="orange-button"
                      onClick={() => pickActivity(_id, duration.time)}
                    >
                      SELECT
                      <img className="down" src={down} alt="" />
                    </button>
                  )}
                </div>
                <div>
                  <p className="text small">{description}</p>
                </div>
                <div className="tag-div">
                  <button className="button-close right">{categoryName}</button>
                  <button className="button-close">{ageGroupBracket}</button>
                </div>
                {activity_id === _id && (
                  <div className="expanded">
                    <p className="text">Select date and time</p>
                    <div className="selector">
                      <DatePicker
                        selected={date}
                        minDate={new Date()}
                        onChange={handleDate}
                        placeholderText="Select Date"
                        className="input-style"
                        onFocus={(e) => (e.target.readOnly = true)}
                        onChangeRaw={(e) => e.preventDefault()}
                      />
                      <DatePicker
                        selected={time}
                        onChange={handleTime}
                        showTimeSelect
                        showTimeSelectOnly
                        minTime={new Date(openFormatted)}
                        maxTime={
                          new Date(closeFormatted) - 1000 * 3600 * duration.time
                        }
                        timeIntervals={60}
                        dateFormat="h:mm aa"
                        timeCaption="Time"
                        placeholderText="Select Time"
                        className="input-style"
                        onFocus={(e) => (e.target.readOnly = true)}
                        onChangeRaw={(e) => e.preventDefault()}
                      />
                    </div>
                    <div className="addButton">
                      <button
                        className="green-button"
                        disabled={!date || !time}
                        onClick={book}
                      >
                        ADD TO CART
                      </button>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state) {
  return {
    count: state.count,
  };
}

export default connect(mapStateToProps)(Activity);
